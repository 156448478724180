import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueRouter from './router'
import router from './router/index'
import _ from 'lodash'
import Progress from 'vue-multiple-progress'
import moment from 'moment'
import Toasted from 'vue-toasted'
import store from './store/store'
import JsonExcel from "vue-json-excel";
import JQuery from 'jquery'
import VueCountryCode from "vue-country-code";
import VueHtml2Canvas from 'vue-html2canvas';
import VueExcelXlsx from "vue-excel-xlsx";
 

window.$ = JQuery

Vue.prototype.moment = moment
Vue.use(VueHtml2Canvas);
Vue.component("downloadExcel", JsonExcel);
Vue.component('VmProgress', Progress)

Vue.use(Vuex)
Vue.use(axios)
Vue.prototype.$axios = axios
Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.use(VueRouter)
Object.defineProperty(Vue.prototype, '$_', { value: _ })
Vue.use(require('vue-moment'))
let Options = {
  position : 'bottom-right',
  duration : 3000,
  keepOnHover : true
}
Vue.use(Toasted, Options)
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}
Vue.use(VueCountryCode);
Vue.use(VueExcelXlsx);
// Vue.use(VueCharts)
// Vue.use

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
