import { VueTelInput } from "vue-tel-input";
import Service from "./../../utils/api/service";

export default {
  name: "login",
  data() {
    return {
      show: false,
      body: {
        email: "",
        password: "",
      },
      isLogin: false,
    };
  },
  components: {
    VueTelInput,
  },
  created() {},
  mounted() {},
  methods: {
    login() {
      this.isLogin = true;
      let body = {
        email: this.body.email,
        password: this.body.password,
      };
      Service.login(body).then((response) => {
        if (response.statusCode) {
          this.$toasted.show(response.message.capitalize());
          this.isLogin = false;
        } else {
          this.$cookies.set("accountId", response.data.account_id);
          this.$cookies.set("accessToken", response.data.access_token);
          this.$cookies.set("refreshToken", response.data.refresh_token);
          this.$router.push({
            name: "dashboard",
            params: { title: "Dashboard" },
          });
          location.reload();
        }
      });
    },
  },
};
