import Pagination from '@/components/share/pagination'
import DatePicker from 'vue2-datepicker'
import Helper from '../../../utils/global/func'
import Service from '../../../utils/api/service'
import TableLoading from './../../../components/share/table-loading'
import 'vue2-datepicker/index.css'

export default {
  name: 'report_list_total_purchased',
  data() {
    return {
      isFetching: true,
      search: {
        phone: '',
        status: '',
        accountId: '',
      },
      data: {
        reports: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
    }
  },
  components: {
    Pagination,
    DatePicker,
    TableLoading,
  },
  created() {
    this.getTotalPurchased()
  },
  watch: {
    '$route.fullPath': function () {
      this.getTotalPurchased()
    },
  },
  mounted() {},
  computed: {},
  methods: {
    getTotalPurchased() {
      let queryPage = this.$root.$route.query.page
      let queryLimit = this.$root.$route.query.limit
      let queryAccountId = this.$root.$route.query.accountId
      let queryPhone = this.$root.$route.query.phone
      let queryStatus = this.$root.$route.query.status

      if (queryPage != undefined) this.data.pagination.page = queryPage
      if (queryLimit != undefined) this.data.pagination.limit = queryLimit
      if (queryPhone != undefined) this.search.phone = queryPhone
      if (queryStatus != undefined) this.search.status = queryStatus
      if (queryAccountId != undefined) this.search.accountId = queryAccountId

      let offset = (this.data.pagination.limit * this.data.pagination.page) - this.data.pagination.limit
      let param = "?limit="+this.data.pagination.limit+"&offset="+offset
      if(this.search.accountId){ param = param+"&accountId="+this.search.accountId }
      if(this.search.phone){ param = param+"&phone="+this.search.phone }
      if(this.search.status){ param = param+"&status="+this.search.status }

      Service.getTotalPurchased(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.getTotalPurchased()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isFetching = false
          this.data.reports = response.data
          this.data.pagination = Helper.calculatePagination(response.meta)
        }
      })
    },

    async searchUser() {
      const query = Object.assign({}, this.$route.query)
      query.phone = this.search.phone
      query.status = this.search.status
      query.accountId = this.search.accountId
      query.page = 1
      await this.$router.push({ query })
    }
  },
}
