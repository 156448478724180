import Helper from "./../../../utils/global/func";
import Service from "./../../../utils/api/service";

export default {
  name: "movie_edit",
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  created() {
  },
  watch: {
    '$route.fullPath': function () {
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    getFullImage(path){
      return Helper.getFullImage(path)
    },

    imageLoadError () {
      this.body.thumbnailLandscape.isReachable = false
    },

    getMovieDetail(){
      this.data.movieId = this.$root.$route.params.id
      Service.getMovieById(this.data.movieId).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.getMovieDetail()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          let movie = response.data
          this.data.movie = movie
          this.body = {
            thumbnail: {
              src: movie.thumbnailUrl,
              file: "",
              presign: ""
            },
            movie: {
              src: movie.movieUrl,
              file: "",
              presign: ""
            },
            trailer: {
              src: movie.trailerUrl,
              file: "",
              presign: ""
            },
            thumbnailPortrait: {
              src: "",
              file: "",
              presign: ""
            },
            thumbnailLandscape: {
              isReachable: true,
              src: this.generalImageLandscapeUrl(movie.thumbnailUrl),
              file: "",
              presign: ""
            },
            movieUrl: movie.movieUrl,
            title: movie.title,
            youtubeLink: "",
            type: movie.type,
            status: movie.status,
            duration: this.msToTime(movie.duration)
          }
        }
      })
    },

    msToTime(duration) {
      // var milliseconds = parseInt((duration % 1000) / 100),
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
    
      return hours + ":" + minutes + ":" + seconds;
    },

    getNameFromUrl(url){
      return url.split('/').pop()
    },

    generalImageLandscapeUrl(url){
      let ext = url.split('.').pop()
      let name = url.split('.'+ext)[0]
      return name+"-landscape."+ext
    },

    chooseImage(e){
      this.body.thumbnail.src = ""

      var file = e.target.files[0]
      var newFile;
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        let fileName = new Date().getTime()+".jpg"
        newFile = Helper.base64toFile(reader.result, fileName)
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }

      setTimeout(() => { this.body.thumbnail.file = newFile }, 1000);
    },

    chooseThumbnailPortrait(e){
      let thumbnailPortraitFile = e.target.files[0];
      if(thumbnailPortraitFile){
        this.body.thumbnailPortrait.src = ""

        var file = thumbnailPortraitFile
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.body.thumbnailPortrait.file = newFile }, 1000);
      }
    },

    chooseThumbnailLandscape(e){
      let thumbnailLandscapeFile = e.target.files[0];
      if(thumbnailLandscapeFile){
        this.body.thumbnailLandscape.src = ""

        var file = thumbnailLandscapeFile
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.body.thumbnailLandscape.file = newFile }, 1000);
      }
    },

    chooseMovie(e){
      let videoFile = e.target.files[0];
      if(videoFile){
        this.body.movie.file = videoFile 
        this.body.movie.src = ""
        this.getDuration(videoFile)
      }
    },

    getDuration(file) {
      var video = document.createElement('video');
      video.preload = 'metadata';
      var duration = 0;
  
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        duration = parseInt(video.duration) * 1000
      }
  
      video.src = URL.createObjectURL(file);
      setTimeout(() => { this.body.duration = this.msToTime(duration) }, 1000);
    },

    chooseTrailer(e){
      let trailerFile = e.target.files[0];
      if(trailerFile){
        this.body.trailer.file = trailerFile 
        this.body.trailer.src = ""
      }
    },

    fileToPath(file){ return window.URL.createObjectURL(file) },

    submitCreateAdvertisement(){
      let validatedMessage = this.validateBody(this.body)
      if(validatedMessage == "ok"){
        this.isCreating = true
        if(this.body.thumbnail.file || this.body.movie.file || this.body.trailer.file){
          this.uploadPresign()
        }else if(this.body.thumbnailLandscape.file){
          this.uploadThumbnailPresign()
        }else{
          this.createMovie()
        }
      }else{
        this.$toasted.show(validatedMessage)
      }
    },

    validateBody(data){
      if(!data.title){
        return "Title cannot be empty!"
      }else if(!data.type){
        return "Type cannot be empty!"
      }else if(data.type == 'free' && !data.movieUrl){
        return "Youtube link cannot be empty!"
      }else{
        return "ok"
      }
    },

    uploadPresign(){
      let body = {
        media:[]
      }

      if(this.body.thumbnail.file){
        let obj = {
          ext: this.body.thumbnail.file.type.split('/').pop(),
          type: "thumbnail",
          filename: new Date().getTime()+"."+this.body.thumbnail.file.type.split('/').pop()
        }
        body.media.push(obj)
        this.mediaForUploading.push('thumbnail')
      }

      if(this.body.movie.file){
        let obj = {
          ext: this.body.movie.file.type.split('/').pop(),
          type: "movie",
          filename: this.body.movie.file.name
        }
        body.media.push(obj)
        this.mediaForUploading.push('movie')
      }

      if(this.body.trailer.file){
        let obj = {
          ext: this.body.trailer.file.type.split('/').pop(),
          type: "trailer",
          filename: this.body.trailer.file.name
        }
        body.media.push(obj)
        this.mediaForUploading.push('trailer')
      }

      Service.uploadPresign(body).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.uploadPresign()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          for(let i=0; i<this.mediaForUploading.length; i++){
            if(this.mediaForUploading[i] == 'thumbnail'){
              this.body.thumbnail.presign = response.data[i]
            }
            if(this.mediaForUploading[i] == 'movie'){
              this.body.movie.presign = response.data[i]
            }
            if(this.mediaForUploading[i] == 'trailer'){
              this.body.trailer.presign = response.data[i]
            }
          }
          this.checkUploadedFile()
        }
      });
    },

    generateThumbnailLandscape(url,fileExt){
      let name = url.split('/').pop()
      let ext = name.split('.').pop()
      let newName = name.split('.'+ext)[0]
      return newName+"-landscape."+fileExt
    },

    uploadThumbnailPresign(){
      let body = {
        media:[]
      }

      if(this.body.thumbnailPortrait.file){
        let obj = {
          ext: this.body.thumbnailPortrait.file.type.split('/').pop(),
          type: "thumbnail",
          filename: this.body.thumbnail.file 
            ? this.generateThumbnailLandscape(this.body.thumbnail.presign.key,this.body.thumbnailPortrait.file.type.split('/').pop())
            : this.generateThumbnailLandscape(this.body.thumbnail.src,this.body.thumbnail.src.split('.').pop())
        }
        body.media.push(obj)
        this.thumbnailExtra.push('portrait')
      }

      if(this.body.thumbnailLandscape.file){
        let obj = {
          ext: this.body.thumbnailLandscape.file.type.split('/').pop(),
          type: "thumbnail",
          filename: this.body.thumbnail.file 
          ? this.generateThumbnailLandscape(this.body.thumbnail.presign.key,this.body.thumbnailLandscape.file.type.split('/').pop())
          : this.generateThumbnailLandscape(this.body.thumbnail.src,this.body.thumbnail.src.split('.').pop())
        }
        body.media.push(obj)
        this.thumbnailExtra.push('landscape')
      }

      if(body.media.length > 0){
        Service.uploadPresign(body).then((response) => {
          if(response.statusCode){
            if(response.statusCode == "4410"){
              Service.refreshToken().then((response) => {
                if(response == "ok"){this.uploadThumbnailPresign()}
              })
            }else{
              this.$toasted.show(response.message.capitalize())
            }
          }else{
            for(let i=0; i<this.thumbnailExtra.length; i++){
              if(this.thumbnailExtra[i] == 'portrait'){
                this.body.thumbnailPortrait.presign = response.data[i]
              }
              if(this.thumbnailExtra[i] == 'landscape'){
                this.body.thumbnailLandscape.presign = response.data[i]
              }
            }
            this.checkThumbnailExtra()
          }
        })
      }else{
        this.createMovie()
      }
    },

    async checkThumbnailExtra(){
      if(this.uploadedThumbnailExtraLength >= this.thumbnailExtra.length){
        this.createMovie()
      }else{
        this.isUploadingThumbnailExtra = true
        if(this.thumbnailExtra.length == 1){
          
          if(this.thumbnailExtra[0] == 'portrait'){
            this.uploadFile(this.body.thumbnailPortrait, 'portrait')
          }else{
            this.uploadFile(this.body.thumbnailLandscape, 'landscape')
          }
        }else{
          if(!this.isUploadedThumbnailExtraFile.portrait){
            await this.uploadFile(this.body.thumbnailPortrait, 'portrait')
          }else{
            await this.uploadFile(this.body.thumbnailLandscape, 'landscape')
          }
        }
      }
    },

    async checkUploadedFile(){
      if(this.body.type == "charge"){
        this.mediaForUploadingFile.trailer = true
      }

      if(this.body.type == "coming_soon"){
        this.mediaForUploadingFile.movie = true
      }

      if(this.mediaUploadedLength >= this.mediaForUploading.length){
        if(this.body.thumbnailLandscape.file){
          this.uploadThumbnailPresign()
        }else{
          this.createMovie()
        }
      }else{
        if(this.mediaForUploading.length == 1){
          if(this.mediaForUploading[0] == 'thumbnail'){
            this.uploadFile(this.body.thumbnail, 'thumbnail')
          }else if(this.mediaForUploading[0] == 'movie'){
            this.uploadFile(this.body.movie, 'movie')
          }else{
            this.uploadFile(this.body.trailer, 'trailer')
          }
        }else{
          if(!this.mediaForUploadingFile.thumbnail){
            await this.uploadFile(this.body.thumbnail, 'thumbnail')
          }else if(!this.mediaForUploadingFile.movie){
            await this.uploadFile(this.body.movie, 'movie')
          }else{
            await this.uploadFile(this.body.trailer, 'trailer')
          }
        }
      }
    },

    async uploadFile(item, type){
      let uploadUrl = item.presign.uploadUrl
      let file = item.file
      if(item){
        let headers = {
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage= parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this)
        };

        await this.$axios
        .put(uploadUrl, file, headers)
        .then(() => {
          if(this.isUploadingThumbnailExtra) {
            this.uploadedThumbnailExtraLength = this.uploadedThumbnailExtraLength+1
            this.isUploadedThumbnailExtraFile.portrait = true
            this.checkThumbnailExtra()
          }else{
            this.mediaUploadedLength = this.mediaUploadedLength+1

            if(type == "thumbnail"){
              this.mediaForUploadingFile.thumbnail = true
            }
            if(type == "movie"){
              this.mediaForUploadingFile.movie = true
            }
            if(type == "trailer"){
              this.mediaForUploadingFile.trailer = true
            }
            
            this.checkUploadedFile()
          }
        })
      }else{
        this.$toasted.show("File cannot be empty!")
      }
    },

    createMovie() {
      let body = {
        title: this.body.title,
        thumbnailUrl: this.body.thumbnail.presign ? this.body.thumbnail.presign.key : this.body.thumbnail.src,
        trailerUrl: this.body.type == 'coming_soon' ? this.body.trailer.presign.key : "",
        movieUrl: this.body.type == 'free' 
        ? this.body.movieUrl 
        : this.body.type == 'charge' 
          ? this.body.movie.presign.key
          : "",
        type: this.body.type,
        uuid: this.getUuid(),
        status: this.body.status,
        duration: this.body.duration && this.body.duration != 0 ? Helper.timeToMillisecond(this.body.duration): 0
      }

      Service.updateMovie(this.data.movieId,body).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.createMovie()}
            })
          }else{
            this.isCreating = false
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          this.isCreating = false
          this.resetBody()
          this.$toasted.show("Movie was updated.")
          this.getMovieDetail()
        }
      });
    },

    getUuid(){
      if(this.body.type == 'charge'){
        if(this.body.movie.presign){
          return this.body.movie.presign.uuid
        }else{
          return this.body.uuid
        }
      }else if(this.body.type == 'coming_soon'){
        if(this.body.trailer.presign){
          return this.body.trailer.presign.uuid
        }else{
          return this.body.uuid
        }
      }else{
        return this.body.uuid
      }
    },

    resetBody(){
      this.$refs.chooseThumbnail.value=null
      this.$refs.chooseThumbnailLandscape.value=null

      if(this.body.type == 'charge'){
        this.$refs.chooseMovie.value=null
      }
      if(this.body.type == 'comming_soon'){
        this.$refs.chooseTrailer.value=null
      }
      
      this.isCreating = false
      this.isUploadingThumbnailExtra = false
      this.data = {
        movieId: "",
        movie:""
      }
      this.body = {
        thumbnail: {
          src: "",
          file: "",
          presign: ""
        },
        movie: {
          src: "",
          file: "",
          presign: ""
        },
        thumbnailPortrait: {
          src: "",
          file: "",
          presign: ""
        },
        thumbnailLandscape: {
          isReachable: true,
          src: "",
          file: "",
          presign: ""
        },
        movieUrl: "",
        title: "",
        youtubeLink: "",
        type: "coming_soon",
        status: "active"
      }
      this.isUploadedFile = {
        thumbnail : false,
        movie : false
      }
      this.mediaForUploading = []
      this.mediaForUploadingFile = {
        thumbnail: false,
        movie: false
      }
      this.mediaUploadedLength = 0
      this.thumbnailExtra = []
      this.uploadedThumbnailExtraLength = 0
      this.isUploadedThumbnailExtraFile = {
        portrait : false,
        landscape : false,
      }
      this.display = {
        form : "general", // enum: general and media
      }
    }
  },
};
