import Menusidebar from './../the-menu'
import service from '../../utils/api/service'

export default {
  name: 'nav_bar',
  data(){
    return{
        display:{
          modal:{
            profile: false,
            sidebar: false,
            logOut:false
          }
        }
    }

  },
  components: { 
    Menusidebar
  },
  created(){

  },
  methods: {
    popup(type){
      if(type == "profile"){
        this.display.modal.profile = true
      }else if(type == 'sidebar'){
        this.display.modal.sidebar = true
      }
      else{
        this.display.modal.profile = false
        this.display.modal.sidebar = false
      }
    },
    closemodel(){
      this.display.modal.profile = false
      this.display.modal.sidebar = false
    },
    closelogOut(){
      this.display.modal.logOut = false
    },
    modalLogout(){
      this.display.modal.logOut = true
    },
    signOut(){
      service.logout();
    }
  }
}