import Service from "./../../../utils/api/service";
import TableLoading from "./../../../components/share/table-loading"
import { mapState } from "vuex";

export default {
  name: "role_list",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      totalUser: "50K",
      search: {
        status: "",
      },
      field: {
        id: "",
        index: "",
      },
      display: {
        modal: {
          delete : {
            index: -1,
            show: false
          }
        }
      },
      data: {
        totalItems: 10,
        page: 1,
        pageSize: 10,
        totalNumPage: 1,
        roles: [],
        roleList: [],
      },
    };
  },
  components: {
    TableLoading
  },
  created() {
    this.getAllUser(10, 0);
  },
  mounted() {},
  computed: mapState(["permissions"]),
  watch: {
    '$route.fullPath': function () {
      this.getAllUser()
      this.getPermission()
    },
    "$store.state.permissions": function() {
      this.getPermission()
    },
  },
  methods: {
    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "Role";
        let permissions = this.permissions;
        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },
    getAllUser(limit, offset) {
      let queryStatus = this.$root.$route.query.status
      if (queryStatus != undefined) this.search.status = queryStatus
      let param = ''
      if (this.search.status) {
        param =
          '?status=' +
          this.search.status +
          '&limit=' +
          limit +
          '&offset=' +
          offset
      } else {
        param = '?limit=' + limit + '&offset=' + offset
      }

      Service.getAllRole(param).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.getAllUser()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          this.isFetching = false
          this.data.roleList = response.data
        }
      });
    },

    async searchStatus() {
      const query = Object.assign({}, this.$route.query)
      query.status = this.search.status
      await this.$router.push({ query })
    },

    deleteRole() {
      this.isDeleting = true
      let roleId = this.data.roleList[this.display.modal.delete.index].id
      Service.deleteRole(roleId).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.deleteRole()}
            })
          }else{
            this.isDeleting = false
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          this.isDeleting = false
          this.data.roleList.splice(this.display.modal.delete.index, 1);
          this.$toasted.show("Role was deleted.")
          this.closeModal()
        }
      });
    },

    linkPage(type, index) {
      if (index >= 0) {
        this.$router.push({
          name: type,
          query: { id: this.data.roleList[index].id },
        });
      } else {
        this.$router.push({
          name: type,
        });
      }
    },

    popupModalDelete(index){
      this.display.modal.delete.index = index
      this.display.modal.delete.show = true
    },

    closeModal() {
      this.display = {
        modal: {
          delete : {
            index: -1,
            show: false
          }
        }
      }
    },

    modalDetail() {
      this.display.modal.detail = true;
    },
  },
};
