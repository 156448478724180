import { VueTelInput } from "vue-tel-input";
import Media from "./../../../components/share/media";
import moment from "moment";
import service from "../../../utils/api/service";

export default {
  name: "notification_edit",
  props: {
    detail: Object,
  },
  data() {
    return {
      media: {
        mediaUrl: "",
        mediaId: "",
      },
      display: {
        modal: {
          formError: false,
          media: false,
        },
        message: {
          responseError: "",
        },
      },
      body: {
        cover: "",
        title: "",
        description: "",
        date: "",
        time: "",
        status: "",
      },
    };
  },
  components: {
    VueTelInput,
    Media,
  },
  computed: {
    dateAndTime: function() {
      let dateTime = {
        date: moment(this.detail.pushAt).format("YYYY-MM-DD"),
        time: moment(this.detail.pushAt).format("HH:mm:ss"),
      };

      return dateTime;
    },
  },
  created() {
    // this.getNotification(this.$route.query.id);
  },
  methods: {
    goBack() {
      this.$parent.display.modal.large = false;
    },
    closeModal() {
      this.display.modal.formError = false;
      this.display.modal.media = false;
    },
    uploadMedia() {
      this.display.modal.media = true;
    },

    getNotification(id) {
      service.getOneNotification(id).then((response) => {
        if (response.message == 'ok') {
          this.detail = response.data
        }
      });
    },

    dateTime() {
      this.body.date = this.dateAndTime.date;
      this.body.time = this.dateAndTime.time;
    },

    update() {
      let id = this.detail.id;
      let dateTime = this.detail.pushAt;

      if (this.body.time || this.body.date) {
        let concatenateDateTime = this.body.time + " " + this.body.date;
        dateTime = moment(concatenateDateTime).format("YYYY-MM-DD[T]HH:mm:ss");
      }

      let body = {
        imageUrl:
          "https://i.pinimg.com/236x/4c/10/bf/4c10bf923844ea45c49328baaf3f8a6f--movieposter-twitter.jpg",
        title: this.detail.title,
        description: this.detail.description,
        pushAt: dateTime,
        status: this.detail.status,
      };

      service.updateNotification(id, body).then((response) => {
        if (response.message == "ok") {
          this.closeModal();
        } else if(response.message == 'PushAt must be greater than now'){
          this.display.modal.formError = true;
          this.display.message.responseError = response.message
        }
      });
    },
  },
};
