import moment from "moment";
import Service from "./../../../utils/api/service";
import Helper from './../../../utils/global/func'

export default {
  name: "advertisement_create",
  data() {
    return {
      isCreating: false,
      display: {
        imagePreview: false,
      },
      body: {
        image: {
          file: "",
          presign: ""
        },
        img: "",
        link: "",
        duration: "00:00:00",
        type: "banner",
        sortOrder: 1,
        status: "active",
      },
      data: {},
    };
  },
  components: {
  },
  computed: {
    date: {
      get() {
        return moment(this.body.date).format("YYYY-MM-DD");
      },

      set(date) {
        this.body.date = Date.parse(date);
      },
    },
  },

  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav);
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("beforeunload", this.preventNav);
  //   });
  // },

  // beforeRouteLeave(to, from, next) {
  //   if (this.body.image.file || this.body.link) {
  //     if (!window.confirm("Leave without saving?")) {
  //       return;
  //     }
  //   }
  //   next();
  // },
  
  created() {},
  methods: {
    preventNav(event) {
      if (!this.body.image.file || !this.body.link) return;
      event.preventDefault();
      event.returnValue = "";
    },

    goBack() {
      this.$router.push({
        name: "advertisement_list",
        query: { page: 1, limit: 10 },
      });
    },
    closeModal() {
      this.display.imagePreview = false
    },
    chooseImage(e){
      let advertiseImage = e.target.files[0];
      if(advertiseImage){
        var file = advertiseImage
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.body.image.file = newFile }, 1000);
      }
    },
    fileToPath(file){ return window.URL.createObjectURL(file) },

    submitCreateAdvertisement(){
      let validatedMessage = this.validateBody(this.body)
      if(validatedMessage == "ok"){
        this.isCreating = true
        if(this.body.image.file){
          this.uploadPresign()
        }else{
          this.createAdvertisement()
        }
      }else{
        this.$toasted.show(validatedMessage)
      }
    },

    uploadPresign(){
      let body = {
        media:[{
          ext: this.body.image.file.type.split('/').pop(),
          type: "advertisement",
          filename: this.body.image.file.name
        }]
      }
      Service.uploadPresign(body).then((response) => {
        this.body.image.presign = response.data
        this.uploadFile(this.body.image.file)
      });
    },

    async uploadFile(file){
      if(file){
        let uploadUrl = this.body.image.presign[0].uploadUrl
        await Service.uploadMedia(uploadUrl, file, file.type)
        .then((response) => {
          if (response == "ok") {
            this.createAdvertisement();
          }else{
            this.$toasted.show("File upload fail!")
          }
        })
      }else{
        this.$toasted.show("File cannot be empty!")
      }
    },

    validateBody(data){
      if(!data.image.file){
        return "Image file cannot be empty!"
      }else if(!data.link){
        return "Link cannot be empty!"
      }else if(!data.type){
        return "Type cannot be empty!"
      }else if(!data.sortOrder){
        return "Sort order cannot be empty!"
      }else if(!data.status){
        return "Status order cannot be empty!"
      }else{
        return "ok"
      }
    },

    imagePreview(){
      if (this.body.image.file) {
        this.display.imagePreview = true
      }
    },

    createAdvertisement() {
      let body = {
        link: this.body.link,
        imageUrl: this.body.image.presign ? this.body.image.presign[0].key : "",
        sortOrder: this.body.sortOrder,
        duration: this.body.duration && this.body.duration != 0 ? Helper.timeToMillisecond(this.body.duration): 0,
        type: this.body.type,
        status: this.body.status,
      };

      Service.createAdvertise(body).then((response) => {
        this.isCreating = false
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.createAdvertisement()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          this.resetBody()
          this.$toasted.show("Advertisement was created.")
        }
      });
    },

    resetBody(){
      this.body = {
        image: {
          file: "",
          presign: ""
        },
        img: "",
        link: "",
        type: "banner",
        sortOrder: 1,
        status: "active"
      }
    }
  },
};
