<template>
  <div id="app">
    <div class="sidebar-container m-scroll">
      <Sidebar></Sidebar>
    </div>
    <Navbar></Navbar>
    <div class="wrapper-container">
      <router-view></router-view>
      <CreateMovie v-for="(createMovie, createMovieIndex) in movieIsCreating" 
        :key="createMovie.title" 
        v-show="routeName == 'movie_create' && createMovie.show"
        :componentIndex="createMovieIndex"
        @submitMovie="creatingMovie"
        @uploadedPercentage="uploadedPercentage"></CreateMovie>

      <UpdateMovie v-for="(updateMovie, updateMovieIndex) in movieIsUpdating" 
        :key="updateMovie.id"
        v-show="routeName == 'movie_edit' && updateMovie.show"
        :componentIndex="updateMovieIndex"
        :isUploading="updateMovie.isUploading"
        @submitMovie="updatingMovie"
        @uploadedPercentage="uploadedPercentage"></UpdateMovie>
    </div>

    <div v-if="movieIsCreating.length > 0" class="block-uploading">
      <div v-for="(movie, movieIndex) in movieIsCreating" 
          :key="(movie, movieIndex)" class="row">
          <div v-if="movie.movie" class="item-uploading">
            <img :src="fileToPath(movie.movie.thumbnail.file)" class="item-thumbnail"/>
            <div class="item-info">
              <p class="item-title">{{movie.movie.title}}</p>
              <span class="item-percentage">({{movie.percentage}}%) Uploading...</span>
            </div>
          </div>
        </div>
    </div>

    <div v-if="movieIsUpdating.length > 0" class="block-uploading">
      <div v-for="(update, updateIndex) in movieIsUpdating"
          :key="(update, updateIndex)" class="row">
          <div v-if="update.movie" class="item-uploading">
            <img v-if="update.movie.thumbnail.src" :src="getFullImage(update.movie.thumbnail.src)" class="item-thumbnail"/>
            <img v-else :src="fileToPath(update.movie.thumbnail.file)" class="item-thumbnail"/>
            <div class="item-info">
              <p class="item-title">{{update.movie.id}} {{update.movie.title}}</p>
              <span class="item-percentage">({{update.percentage}}%) Uploading...</span>
            </div>
          </div>
        </div>
    </div>

    <div v-if="!permissions && routeName != 'login'" class="loading-overlay">
      <img :src="require('@/assets/images/adc-loading.gif')" />
    </div>
  </div>
</template>
<script>
import Helper from "./utils/global/func"
import Sidebar from "./components/the-menu";
import Navbar from "./components/the-navbar";
import CreateMovie from "./components/movie/create"
import UpdateMovie from "./components/movie/edit"
import { mapState } from 'vuex'
import VueCookies from "vue-cookies";
import Service from "./utils/api/service";

export default {
  name: "app",
  data() {
    return {
      isLogging: true,
      routeName: ""
    };
  },
  components: {
    Sidebar,
    Navbar,
    CreateMovie,
    UpdateMovie
  },
  created() {
    this.checkRouteName();
    this.getAccountDetail();
  },
  watch: {
    "$route.fullPath": function () {
      this.checkRouteName();
    },
  },
  computed: {
    ...mapState([
      'movieList',
      'movieIsCreating',
      'movieIsUpdating',
      'permissions'
    ])
  },
  methods: {
    getAccountDetail() {
      let token = VueCookies.get("accessToken");
      let refreshToken = VueCookies.get("refreshToken");
      if (token || refreshToken) {
        Service.getAccountDetail().then((response) => {
          if (response.statusCode) {
            if (response.statusCode == "4410") {
              Service.refreshToken().then((response) => {
                if (response == "ok") {
                  this.storeAccountInfo();
                }
              });
            } else {
              this.$toasted.show(response.message.capitalize());
            }
          } else {
            this.$store.dispatch("storeAccountInfo", response.data);
          }
        })
      }
    },
    preventNav(event) {
      if (!this.movieBody) { return }
      event.preventDefault();
      event.returnValue = "";
    },
    checkRouteName(){
      this.routeName = this.$root.$route.name
    },
    fileToPath(file){
      return window.URL.createObjectURL(file) 
    },
    getFullImage(path){
      return Helper.getFullImage(path)
    },
    creatingMovie(val){
      if(val.body){
        let newObj = { 
          movie: "", 
          percentage: 0,
          show: true,
          cancelToken: ""
        }
        this.movieIsCreating[val.componentIndex].movie = val.body
        this.movieIsCreating[val.componentIndex].show = false
        this.movieIsCreating[val.componentIndex].cancelToken = val.cancelToken
        this.movieIsCreating.push(newObj)
      }else{
        this.movieIsCreating.splice(val.componentIndex, 1)
      }
    },
    updatingMovie(val){
      if(val.body){
        this.movieList.find((item, i) => {
          if (item.id === val.body.id) {
            this.movieList[i].isUploading = true
            this.$store.dispatch("setMovieList", this.movieList);
            return true;
          }
        })
        let newObj = { 
          movie: "", 
          percentage: 0,
          show: true,
          isUploading: false
        }
        this.movieIsUpdating[val.componentIndex].movie = val.body
        this.movieIsUpdating[val.componentIndex].show = false
        this.movieIsUpdating[val.componentIndex].isUploading = true
        this.movieIsUpdating.push(newObj)
      }else{
        this.movieIsUpdating.splice(val.componentIndex, 1)
      }
    },
    uploadedPercentage(val){
      if(val.type == 'create'){
        this.movieIsCreating[val.componentIndex].percentage = val.percentage
        val.item = this.movieIsCreating[val.componentIndex]
      }else{
        this.movieIsUpdating[val.componentIndex].percentage = val.percentage
        val.item = this.movieIsUpdating[val.componentIndex]
      }
      this.$store.commit("UPDATE_UPLOAD_PERCENTAGE", val);
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.movieBody) {
      if (!window.confirm("If you leave, Movie upload will interrupt.")) {
        return;
      }
    }
    next();
  },
};
</script>
<style>
* {
  box-sizing: border-box;
}
body {
  /* font-family: var(--font-family-monospace); */
  font-display: var(--font-family-monospace);
  font-weight: 300 !important;
  margin: 0;
  padding: 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
a {
  text-decoration: none;
}
input.mx-input {
  width: 320px;
  height: 30px;
  outline: none;
  border-color: #ccc;
}
input.mx-input:hover {
  border-color: #ccc;
}
.loading-overlay{
  width:100%;height:100%;position:fixed;top:0;left:0;background:#1a222e;z-index:9999
}
.loading-overlay img{
  width:80px;height:80px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)
}
.vti__dropdown-list.below {
  top: 33px;
  display: none !important ;
}
.vti__dropdown-arrow {
  display: none !important;
}
.vti__dropdown {
  padding: 12px !important;
}
.vti__input {
  padding-left: 12px !important;
  padding-top: 5px !important;
}
.sidebar-container {
  width: 235px;
  height: 100vh;
  position: fixed;
  top: 52px;
  left: 0;
  padding-bottom: 75px;
  background: #19222e;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--white);
}

.block-uploading{
  width:235px;
  padding:12px;
  background:white;
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  position:fixed;
  bottom:0;
  left:0;
  z-index:1;
}
.item-uploading{
  width: 100%;
  padding: 12px;
  margin-bottom:3px;
  background:#f2f2f8;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}
.item-thumbnail{
  height: 50px;border-radius: 2px;
}
.item-info{
  width: 100%;margin-left: 12px;
}
.item-title{
  margin-bottom:0px;
  color: #fdb432;
  font-weight: 600;
  font-size:14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-percentage{
  color:black;font-size:13px
}

.mx-datepicker .mx-input{
  width: 100% !important;
  border: none
}
.mx-datepicker{
  width: 100% !important;
}
.vue-country-select .dropdown{
  padding: 6px !important;
  
}

@media screen and (max-width: 1200px) {
  .sidebar-container {
    display: none;
  }
  .wrapper-container {
    padding: 60px 5px 5px 5px;
  }
}
</style>