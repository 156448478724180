import Pagination from "@/components/share/pagination";
import DatePicker from "vue2-datepicker";
import Helper from "./../../../utils/global/func";
import Service from "./../../../utils/api/service";
import TableLoading from "./../../../components/share/table-loading";
import "vue2-datepicker/index.css";
import { mapState } from "vuex";

export default {
  name: 'user_list',
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      search: {
        phone: "",
        status: "",
        startDate: ""
      },
      field: {
        index: '',
        type: '',
      },
      display: {
        modal: {
          large: false,
          medium: false,
          small: false,
          detail: false,
        },
      },
      data: {
        usersList: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      allowedPermission: {},
    };
  },
  components: {
    Pagination,
    DatePicker,
    TableLoading,
  },
  created() {
    this.getAllUser()
  },
  watch: {
    "$route.fullPath": function() {
      this.getAllUser()
    }
  },
  mounted() {},
  computed: mapState(["permissions"]),
  methods: {

    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },

    getAllUser() {
      let queryPage = this.$root.$route.query.page;
      let queryLimit = this.$root.$route.query.limit;
      let queryPhone = this.$root.$route.query.phone;
      let queryStatus = this.$root.$route.query.status;
      let queryFromDate = this.$root.$route.query.fromDate;
      let queryToDate = this.$root.$route.query.toDate;
      if (queryPage != undefined) this.data.pagination.page = queryPage;
      if (queryLimit != undefined) this.data.pagination.limit = queryLimit;
      if (queryPhone != undefined) this.search.phone = queryPhone;
      if (queryStatus != undefined) this.search.status = queryStatus;
      if (queryFromDate != undefined) this.search.startDate = queryFromDate;
      if (queryToDate != undefined) this.search.endDate = queryToDate;


      let offset =
        this.data.pagination.limit * this.data.pagination.page -
        this.data.pagination.limit;
      let param = "";

      if (this.search.status && this.search.phone) {
        param =
          "?status=" +
          this.search.status +
          "&phone=" +
          this.search.phone +
          "&limit=" +
          this.data.pagination.limit +
          "&offset=" +
          offset;
      } else if (this.search.status) {
        param =
          "?status=" +
          this.search.status +
          "&limit=" +
          this.data.pagination.limit +
          "&offset=" +
          offset;
      } else if (this.search.phone) {
        let phone = "";
        if (this.search.phone.charAt(0) == "+") {
          phone = "%2B" + this.search.phone.substring(1);
        } else {
          phone = this.search.phone;
        }
        param =
          "?phone=" +
          phone +
          "&limit=" +
          this.data.pagination.limit +
          "&offset=" +
          offset;
      } else {
        param = "?limit=" + this.data.pagination.limit + "&offset=" + offset;
      }

      if(this.search.startDate){
        param = param+"&fromDate="+this.search.startDate
      }
      if(this.search.endDate){
        param = param+"&toDate="+this.search.endDate
      }

      Service.getAllUser(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.getAllUser();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.isFetching = false
          this.data.usersList = response.data
          this.data.pagination = Helper.calculatePagination(response.meta)
        }
      })
    },

    async searchUser() {
      if(this.search.startDate && this.search.endDate){
        if(this.search.startDate >= this.search.endDate){
          this.$toasted.show("From Data have to bigger than To Date.");
        }else{
          this.data.pagination.page = 1
          this.getAllUser()
        }
      }else{
        this.data.pagination.page = 1
        this.getAllUser()
      }
    },

    popup(type, index) {
      this.field.index = index
      this.field.type = type
      this.display.modal.small = true
    },

    confirmUpdate() {
      this.isDeleting = true;
      let id = this.data.usersList[this.field.index].id;
      let body = {
        status: this.field.type,
      }

      Service.updateUser(id, body).then((response) => {
        this.isDeleting = false;
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.confirmUpdate();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.display.modal.small = false

          if (body.status == "active") {
            this.data.usersList[this.field.index].status = "active";
          } else {
            this.data.usersList[this.field.index].status = "inactive";
          }
          this.$toasted.show("Customer was updated.");
        }
      })
    },

    linkPage(type, index) {
      if (index >= 0) {
        this.$router.push({
          name: type,
          query: { id: this.data.usersList[index]._id },
        })
      } else {
        this.$router.push({
          name: type,
        })
      }
    },

    closeModal() {
      this.display.modal.large = false
      this.display.modal.medium = false
      this.display.modal.small = false
      this.display.modal.detail = false
    },
  },
}
