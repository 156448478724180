import Media from "./../../../components/share/media";
import Service from "../../../utils/api/service";
import Helper from './../../../utils/global/func'

export default {
  name: "advertisement_edit",
  props: {
    detail: Object,
  },
  data() {
    return {
      isUpdating: false,
      display: {
        imagePreview: false,
      },
      body: {
        image: {
          file: "",
          presign: ""
        },
        img: "",
        link: "",
        type: "",
        duration: "00:00:00",
        sortOrder: 1,
        status: "",
      },
      advertisementDetail: "",
    };
  },
  components: {
    Media,
  },
  computed: {},
  created() {
    this.validateAdvertisementInfo()
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    validateAdvertisementInfo(){
      this.detail.duration = this.msToTime(this.detail.duration)
      this.advertisementDetail = this.detail
    },

    msToTime(duration) {
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
    
      return hours + ":" + minutes + ":" + seconds;
    },

    imagePreview(){
      if (this.body.image.file || this.advertisementDetail.imageUrl) {
        this.display.imagePreview = true
      }
    },

    closeModal() {
      if (this.display.imagePreview) {
        this.display.imagePreview = false
      }else
      this.$parent.display.modal.large = false
    },
    
    getFullImage(path) {
      return Helper.getFullImage(path)
    },

    getNameFromUrl(url) {
      return url.split('/').pop()
    },

    chooseImage(e){
      this.body.image.file = e.target.files[0];
    },

    fileToPath(file){ return window.URL.createObjectURL(file) },

    submitUpdateAdvertisement(){
      let validatedMessage = this.validateBody(this.advertisementDetail)
      if(validatedMessage == "ok"){
        this.isUpdating = true
        if(this.body.image.file){
          this.uploadPresign()
        }else{
          this.updateAdvertisement()
        }
      }else{
        this.$toasted.show(validatedMessage)
      }
    },
    
    validateBody(data){
      if(!data.imageUrl){
        return "Image file cannot be empty!"
      }else if(!data.link){
        return "Link cannot be empty!"
      }else if(!data.type){
        return "Type cannot be empty!"
      }else if(!data.sortOrder){
        return "Sort order cannot be empty!"
      }else if(!data.status){
        return "Status order cannot be empty!"
      }else{
        return "ok"
      }
    },

    uploadPresign(){
      let body = {
        media:[{
          ext: this.body.image.file.type.split('/').pop(),
          type: "advertisement",
          filename: this.body.image.file.name
        }]
      }
      Service.uploadPresign(body).then((response) => {
        this.body.image.presign = response.data
        this.uploadFile(this.body.image.file)
      });
    },

    async uploadFile(file){
      if(file){
        let uploadUrl = this.body.image.presign[0].uploadUrl
        await Service.uploadMedia(uploadUrl, file, file.type)
        .then((response) => {
          if (response == "ok") {
            this.updateAdvertisement();
          }else{
            this.$toasted.show("File upload fail!")
          }
        })
      }else{
        this.$toasted.show("File cannot be empty!")
      }
    },

    updateAdvertisement() {
      let id = this.advertisementDetail.id;
      if (this.body.image.file) {
        this.advertisementDetail.imageUrl = this.body.image.presign ? this.body.image.presign[0].key : ""
      }

      let body = {
        link: this.advertisementDetail.link,
        imageUrl: this.advertisementDetail.imageUrl,
        sortOrder: this.advertisementDetail.sortOrder,
        duration: this.advertisementDetail.duration && this.advertisementDetail.duration != 0 ? Helper.timeToMillisecond(this.advertisementDetail.duration): 0,
        status: this.advertisementDetail.status,
      };

      Service.updateAdvertise(id, body).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.updateAdvertisement()}
            })
          }else {
            this.isUpdating = false
            this.$toasted.show(response.message.capitalize())
          }
        }else {
          const updatedBody = Object.assign({}, body)
          updatedBody.id = this.advertisementDetail.id
          updatedBody.type = this.advertisementDetail.type
          updatedBody.createdAt = this.advertisementDetail.createdAt
          updatedBody.updatedAt = this.advertisementDetail.updatedAt
          this.$emit('clicked', updatedBody)

          this.isUpdating = false
          this.$parent.display.modal.large = false
          this.$toasted.show('Advertisement was updated.')
        }
      });
    },
  },
};
