export default {
  name: 'detail-users',
  data() {
    return {
      movie: {
        cover: "https://m.media-amazon.com/images/M/MV5BZTliNWJhM2YtNDc1MC00YTk1LWE2MGYtZmE4M2Y5ODdlNzQzXkEyXkFqcGdeQXVyMzY0MTE3NzU@._V1_.jpg",
        video: "https://www.edgehill.ac.uk/events/files/2020/10/Us.jpg",
        title: "Title Movie",
        description: "**********",
        date: "12-09-98",
        type: "Paid",
        status: "Enable",
      },
    }
  },
  props: {
    
  },
  components: { 
  },
  methods: {
    open(link) {
      this.$electron.shell.openExternal(link)
    }
  }
}