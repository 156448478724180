import Service from './../../../utils/api/service'
import Pagination from '@/components/share/pagination'
import Update from './../edit'
import DetailNotification from './../../../components/card/detail-notification'
import DatePicker from 'vue2-datepicker'
import Helper from './../../../utils/global/func'
import TableLoading from './../../../components/share/table-loading'
import 'vue2-datepicker/index.css'
import { mapState } from "vuex"

export default {
  name: 'notification_list',
  data() {
    return {
      isFetching: true,
      isUpdating: false,
      isDeleting: false,
      search: {
        startDate: 0,
        endDate: 0,
      },
      fields: {
        id: '',
        index: '',
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          imagePreview: {
            show: false,
            index: "",
          }
        },
      },
      data: {
        limit: 10,
        detail: {},
        notifications: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      update:{
        image: {
          file: "",
          src: "",
          presign: ""
        },
        index: -1,
        body: ""
      }
    }
  },
  components: {
    Pagination,
    Update,
    DetailNotification,
    DatePicker,
    TableLoading,
  },
  created() {
    this.getAllNotification()
  },
  watch: {
    '$route.fullPath': function () {
      this.getAllNotification()
      // this.getPermission()
    },
    // "$store.state.permissions": function() {
    //   this.getPermission()
    // },
  },
  mounted() {},
  computed: mapState(["permissions"]),
  methods: {
    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "Notification";
        let permissions = this.permissions;

        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },
    getFullImage(path) {
      return Helper.getFullImage(path)
    },

    chooseImage(e) {
      let notificationCover = e.target.files[0];
      if(notificationCover){
        var file = notificationCover
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.update.image.file = newFile }, 1000);
      }
    },

    fileToPath(file) {
      return window.URL.createObjectURL(file);
    },

    linkPage(type, index) {
      if (index >= 0) {
        this.$router.push({
          name: type,
          query: { id: this.data.notifications[index].id },
        })
      } else
        this.$router.push({
          name: type,
        })
    },

    getAllNotification() {
      let queryPage = this.$root.$route.query.page
      let queryLimit = this.$root.$route.query.limit
      let queryStartDate = this.$root.$route.query.startDate
      let queryEndDate = this.$root.$route.query.endDate
      if (queryPage != undefined) this.data.pagination.page = queryPage
      if (queryLimit != undefined) this.data.pagination.limit = queryLimit
      if (queryStartDate != undefined) this.search.startDate = queryStartDate
      if (queryEndDate != undefined) this.search.endDate = queryEndDate

      let offset =
        this.data.pagination.limit * this.data.pagination.page -
        this.data.pagination.limit
      let param = ''
      if (this.search.startDate != 0 && this.search.endDate != 0) {
        param =
          '?sort=DESC&limit=' +
          this.data.pagination.limit +
          '&offset=' +
          offset +
          '&minDate=' +
          this.search.startDate +
          '&maxDate=' +
          this.search.endDate
      } else {
        param =
          '?sort=DESC&limit=' + this.data.pagination.limit + '&offset=' + offset
      }
      Service.getAllNotification(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.getAllNotification()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isFetching = false
          this.data.notifications = response.data
          this.data.pagination = Helper.calculatePagination(response.meta)
        }
      })
    },

    async clearDate() {
      this.search = {
        startDate: 0,
        endDate: 0,
      }
    },

    async searchAdvertisement() {
      const query = Object.assign({}, this.$route.query)
      query.startDate = this.search.startDate
      query.endDate = this.search.endDate
      query.page = 1
      await this.$router.push({ query })
    },

    filter() {
      let startDate = this.search.startDate
      let endDate = this.search.endDate
      let limit = this.data.limit

      if (startDate && endDate) {
        Service.getFilterNotification(limit, startDate, endDate).then(
          (response) => {
            if (response.statusCode) {
              if (response.statusCode == '4410') {
                Service.refreshToken().then((response) => {
                  if (response == 'ok') {
                    this.filter()
                  }
                })
              } else {
                this.$toasted.show(response.message.capitalize())
              }
            } else {
              this.data.notifications = response.data
            }
          },
        )
      }
    },

    popupModalDelete(index) {
      this.display.modal.delete.index = index
      this.display.modal.delete.show = true
    },

    deleteNotification() {
      this.isDeleting = true
      let notificationId = this.data.notifications[
        this.display.modal.delete.index
      ].id
      Service.deleteNotification(notificationId).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.deleteNotification()
              }
            })
          } else {
            this.isDeleting = false
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isDeleting = false
          this.data.notifications.splice(this.display.modal.delete.index, 1)
          this.$toasted.show('Notification was deleted.')
          this.closeModal()
        }
      })
    },

    imagePreview(index) {
      if (this.data.notifications[index].imageUrl) {
        this.display.modal.imagePreview.index = index
        this.display.modal.imagePreview.show = true
      }
    },

    modalUpdate(index){
      this.update.index = index
      this.update.body = this.data.notifications[index]
      this.update.image.src = this.data.notifications[index].imageUrl
    },

    getNameFromUrl(url){
      return url.split('/').pop()
    },

    submitUpdateNotification(){
      let validatedMessage = this.validateBody(this.update.body)
      if(validatedMessage == "ok"){
        this.isUpdating = true
        if(this.update.image.file){
          this.uploadPresign()
        }else{
          this.updateNotification()
        }
      }else{
        this.$toasted.show(validatedMessage)
      }
    },

    validateBody(data) {
      if (!data.title) {
        return "Title cannot be empty!";
      } else if (!data.description) {
        return "Description cannot be empty!";
      } else {
        return "ok";
      }
    },

    uploadPresign() {
      let body = {
        media: [
          {
            ext: this.update.image.file.type.split("/").pop(),
            type: "notification",
            filename: this.update.image.file.name,
          },
        ],
      };
      Service.uploadPresign(body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.uploadPresign()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.update.image.presign = response.data;
          this.uploadFile(this.update.image.file);
        }
      });
    },

    async uploadFile(file) {
      if (file) {
        let uploadUrl = this.update.image.presign[0].uploadUrl;
        await Service
          .uploadMedia(uploadUrl, file, file.type)
          .then((response) => {
            if (response == "ok") {
              this.updateNotification();
            }else{
              this.$toasted.show("File upload fail!");
            }
          });
      } else {
        this.$toasted.show("File cannot be empty!");
      }
    },

    updateNotification() {
      let body = {
        imageUrl: this.update.image.presign
          ? this.update.image.presign[0].key
          : this.update.body.imageUrl,
        title: this.update.body.title,
        description: this.update.body.description
      };

      Service.updateNotification(this.update.body.id, body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.updateNotification()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.data.notifications[this.update.index] = this.update.body
          if(this.update.image.presign){
            this.data.notifications[this.update.index].imageUrl = this.update.image.presign[0].key
          }
          this.isUpdating = false
          this.closeModal()
          this.$toasted.show("Notification was updated.");
        }
      });
    },

    closeModal() {
      this.update = {
        image: {
          file: "",
          src: "",
          presign: ""
        },
        index: -1,
        body: ""
      }
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          imagePreview: {
            show: false,
            index: "",
          }
        },
      }
    },
  },
}
