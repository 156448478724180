import Service from "../../utils/api/service";
import Helper from "./../../utils/global/func";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Pagination from "@/components/share/pagination"

export default {
  name: "movie_sale",
  data() {
    return {
      isGenerating: false,
      isExporting: false,
      isGenerated: false,
      data: {
        histories: "",
        historyDetail: "",
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      body: {
        movieId: "",
        startDate: "",
        endDate: "",
      },
      boxsearch: {
        key: "",
        results: [],
        focused: false,
        loading: false,
        loadingScroll: false,
        limit: 10,
        page: 1,
      },
    };
  },
  components: {
    DatePicker,
    Pagination
  },
  created() {},
  watch: {
    '$route.fullPath': function () {
      this.getUserRent()
    },
  },
  methods: {
    onSelect({name, iso2, dialCode}) {
      this.body.countryCode = dialCode
    },

    getFullImage(path) {
      return Helper.getFullImage(path);
    },

    generatePDF(){
      var doc = new jsPDF();
      var specialElementHandlers = {
        '#editor': function (element, renderer) {
            return true;
        }
      };
      doc.fromHTML($('#content').html(), 15, 15, {
        'elementHandlers': specialElementHandlers
      });
      doc.save('sample-file.pdf');
    },

    getUserRent() {
      if(this.body.movieId){
        this.isGenerating = true
        let queryPage = this.$root.$route.query.page
        let queryLimit = this.$root.$route.query.limit
        if(queryLimit != undefined) this.data.pagination.limit = queryLimit
        if(queryPage != undefined) this.data.pagination.page = queryPage

        let offset = this.data.pagination.limit * this.data.pagination.page - this.data.pagination.limit
        let params = "?limit="+this.data.pagination.limit+"&offset=" +offset
        
        if(this.body.movieId) params = params + "&movieId=" + this.body.movieId
        if(this.body.startDate) params = params + "&startDate=" + this.body.startDate+" 00:00:00"
        if(this.body.endDate) params = params + "&endDate=" + this.body.endDate+" 23:59:59"

        Service.getMovieSale(params)
        .then((response) => {
          console.log(response)
          if (response.statusCode) {
            if(response.statusCode == "4410"){
              Service.refreshToken().then((response) => {
                if(response == "ok"){this.getUserRent()}
              })
            }else{
              this.$toasted.show(response.message.capitalize())
            }
          } else {
            this.isGenerating = false
            this.data.histories = response
          }
        })
      }else{
        this.$toasted.show("Movie is required.")
      }
      
    },

    searchMovie() {
      let param = "";
      if (this.boxsearch.key) param = "?search=" + this.boxsearch.key;

      Service.getAllMovie(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.searchMovie();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.boxsearch.results = response.data;
        }
      });
    },

    selectMovie(index) {
      this.boxsearch.key = this.boxsearch.results[index].title;
      this.body.movieId = this.boxsearch.results[index].id;
      this.boxsearch.focused = false
    },

    clearMovie(){
      this.body.movieId = ""
      this.boxsearch = {
        key: "",
        results: [],
        focused: false,
        loading: false,
        loadingScroll: false,
        limit: 10,
        page: 1,
      }
    },

    historyDetail(history){
      this.data.historyDetail = history
    },

    msToTime(duration) {
      // var milliseconds = parseInt((duration % 1000) / 100),
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
    
      return hours + ":" + minutes + ":" + seconds;
    },

    closeModal(){
      this.data.historyDetail = ""
    },
  },

  beforeMount() {
    document.body.addEventListener("mouseup", this.handlemouseup);
  },
  beforeDestroy() {
    document.body.removeEventListener("mouseup", this.handlemouseup);
  },
};
