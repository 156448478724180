import service from './../../../utils/api/service'

export default {
  props: {
    data: Object,
    method: { type: Function }
  },
  name: "media",
  data() {
    return {
      previewImage: "",
      loading: false,
      media: "",
      fields: {
        mediaId: "",
      },
      display: {
        tab: "server",
      },
      pagination: {
        limit: 8,
        page: 1
      },
      file:''
    }
  },

  components: {
  },

  created() {
    this.getData(this.pagination.limit);
  },

  methods: {
    tab(type) { this.display.tab = type },

    closeMedia(){
      this.method();
    },

    getData(limit) {
      service.getMedia(limit, this.pagination.page).then((response) => {
        if (response.status == 1) {
          this.media = response.data
        } else {
          console.log("Hello world")
        }
      }).catch(err => {
        this.loading = true
        console.log(err)
      })
    },  

    showMore(){
      this.pagination.limit += 8
      this.getData(this.pagination.limit);
    },

    deleteItem(index) {
      this.fields.mediaId = this.media.records[index]._id

      if (this.fields.mediaId) {
        let body = {
          "_id": this.fields.mediaId,
        }
        service.deleteMedia(body).then((response) => {
          if (response.status == 1) {
            this.media.records.splice(index, 1)
          }
        })
      }
    },

    getMedia(index) {
      this.data.media_id = this.media.records[index]._id
      this.data.media_url = this.media.records[index].url
      this.method();
    },

    selectImage() {
      this.$refs.file.click()
    },

    handleFilesUpload() {
      this.file = this.$refs.file.files[0];      

      let input = this.$refs.file
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },

    submitFiles(){
      let formData = new FormData();
      formData.append('file', this.file);

      let body = {
        "url": formData
      }

      service.createdMedia(body).then((res) => {
          if (res.data.status === 1) {
            this.previewImage = res.data
            // this.media.records.unshift(res.data.data)
          } 
      })
    },
  }
};