import { VueTelInput } from "vue-tel-input";
import Media from "../../../components/share/media";
import moment from "moment";
import Service from "./../../../utils/api/service";
import Helper from "./../../../utils/global/func";

export default {
  name: "notification_create",
  data() {
    return {
      isCreating: false,
      media: {
        mediaUrl: "",
        mediaId: "",
      },
      display: {
        modal: {
          formError: false,
          media: false,
        },
        message: {
          responseError: "",
        },
      },
      body: {
        image: {
          file: "",
          presign: "",
        },
        title: "",
        description: ""
      },
      data: {},
    };
  },
  components: {
    VueTelInput,
    Media,
  },
  computed: {
    date: {
      get() {
        return moment(this.body.date).format("YYYY-MM-DD");
      },

      set(date) {
        this.body.date = date;
      },
    },
  },
  created() {},

  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav);
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("beforeunload", this.preventNav);
  //   });
  // },

  // beforeRouteLeave(to, from, next) {
  //   if (
  //     this.body.image.file ||
  //     this.body.title ||
  //     this.body.description ||
  //     this.body.date ||
  //     this.body.time
  //   ) {
  //     if (!window.confirm("Leave without saving?")) {
  //       return;
  //     }
  //   }
  //   next();
  // },

  methods: {
    preventNav(event) {
      if (
        !this.body.image.file ||
        !this.body.title ||
        !this.body.description ||
        !this.body.date ||
        !this.body.time
      )
        return;
      event.preventDefault();
      event.returnValue = "";
    },

    goBack() {
      this.$router.push({
        name: "notification_list",
        query: { page: 1, limit: 10 },
      });
    },
    closeModal() {
      this.display.modal.formError = false;
      this.display.modal.media = false;
    },
    uploadMedia() {
      this.display.modal.media = true;
    },

    chooseImage(e) {
      let notificationCover = e.target.files[0];
      if(notificationCover){
        var file = notificationCover
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.body.image.file = newFile }, 1000);
      }
    },

    fileToPath(file) {
      return window.URL.createObjectURL(file);
    },

    submitCreateAdvertisement() {
      let validatedMessage = this.validateBody(this.body);
      if (validatedMessage == "ok") {
        this.isCreating = true;
        if (this.body.image.file) {
          this.uploadPresign();
        } else {
          this.createNotification();
        }
      } else {
        this.$toasted.show(validatedMessage);
      }
    },

    validateBody(data) {
      if (!data.title) {
        return "Title cannot be empty!";
      } else if (!data.description) {
        return "Description cannot be empty!";
      } else {
        return "ok";
      }
    },

    uploadPresign() {
      let body = {
        media: [
          {
            ext: this.body.image.file.type.split("/").pop(),
            type: "notification",
            filename: this.body.image.file.name,
          },
        ],
      };
      Service.uploadPresign(body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.uploadPresign()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.body.image.presign = response.data;
          this.uploadFile(this.body.image.file);
        }
      });
    },

    async uploadFile(file) {
      if (file) {
        let uploadUrl = this.body.image.presign[0].uploadUrl;
        await Service
          .uploadMedia(uploadUrl, file, file.type)
          .then((response) => {
            if (response == "ok") {
              this.createNotification();
            }else{
              this.$toasted.show("File upload fail!");
            }
          });
      } else {
        this.$toasted.show("File cannot be empty!");
      }
    },

    createNotification() {
      let body = {
        imageUrl: this.body.image.presign
          ? this.body.image.presign[0].key
          : "",
        title: this.body.title,
        description: this.body.description
      };

      Service.pushNotification(body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.createNotification()}
            })
          }else{
            this.isCreating = false;
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isCreating = false;
          this.resetBody();
          this.$toasted.show("Notification was created.");
        }
      });
    },

    resetBody() {
      this.body = {
        image: {
          file: "",
          presign: "",
        },
        title: "",
        description: "",
        date: "",
        time: "",
        status: "",
      };
    },
  },
};
