const func = {}

func.calculatePagination = function (data) {
  let totalPage = data.total / data.limit
  let pagination = {
    limit: data.limit,
    page: ((data.offset + data.limit) - data.limit) + 1,
    total: data.total,
    totalPage: totalPage > parseInt(totalPage) ? parseInt(totalPage) + 1 : parseInt(totalPage),
  }
  return pagination
};

func.base64toFile = function (dataurl, filename) {
  var arr = dataurl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: "image/jpeg" });
};

func.getFullImage = function (path) {
  return process.env.VUE_APP_BASE_URL_IMAGE + path + "?cache=none"
};

func.timeToMillisecond = function (t) {
  var h = Number(t.split(':')[0]) * 60 * 60
  var m = Number(t.split(':')[1]) * 60
  var s = Number(t.split(':')[2]);
  return (h + m + s) * 1000
};

func.randomString = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

func.dateTimeToUTC = function (dateString, timeSting){
  var date = new Date(dateString+" "+timeSting)
  date.setHours( date.getHours() + 15 )

  var y = date.getFullYear() > 9 ? date.getFullYear() : '0'+date.getFullYear()
  var m = date.getMonth()+1 > 9 ? date.getMonth()+1 : '0'+(date.getMonth()+1)
  var d = date.getDate() > 9 ? date.getDate() : '0'+date.getDate()
  var h = date.getHours() > 9 ? date.getHours() : '0'+date.getHours()
  var min = date.getMinutes() > 9 ? date.getMinutes() : '0'+date.getMinutes()
  var s = date.getSeconds() > 9 ? date.getSeconds() : '0'+date.getSeconds()

  var r = y+"-"+m+"-"+d+" "+h+":"+min+":"+s
  return new Date(r).toISOString()
}

export default func;