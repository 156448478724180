import { mapState } from "vuex";

export default {
  name: "the-menu",
  data() {
    return {
      display: {
        menu: "dashboard"
      },
      permissions: [],
      menu_list: [],
      menu: [
        {
          name: "Dashboard",
          routeName: "dashboard",
          title: "REPORT",
          icon: "fas fa-tachometer-alt",
        },
        {
          name: "Customer Management",
          routeName: "user_list",
          title: "CUSTOMER",
          icon: "fas fa-users",
        },
        {
          name: "Movie Management",
          routeName: "movie_list",
          title: "MOVIE",
          icon: "fas fa-film",
        },
        {
          name: "Advertisement",
          routeName: "advertisement_list",
          title: "ADVERTISEMENT",
          icon: "fas fa-bullhorn",
        },
        {
          name: "Payment Method",
          routeName: "payment_list",
          title: "PAYMENT",
          icon: "fas fa-money-check",
        },
        {
          name: "Notification",
          routeName: "notification_list",
          title: "NOTIFICATION",
          icon: "far fa-bell",
        },
        {
          name: "Customer",
          routeName: "report_customer",
          title: "REPORT",
          icon: "fas fa-file-contract",
        },
        {
          name: "Order Report",
          routeName: "report",
          title: "REPORT",
          icon: "fas fa-file-contract",
        },
        {
          name: "Rental",
          routeName: "user_rent",
          title: "REPORT",
          icon: "fas fa-eye",
        },
        {
          name: "Admin Accounts",
          routeName: "account_list",
          title: "ADMIN",
          subTitle: "Admin",
          icon: "fas fa-users",
        },
        {
          name: "Role Management",
          routeName: "role_list",
          title: "ROLE",
          icon: "fas fa-users-cog",
        },
        {
          name: "App Version",
          routeName: "app_version_list",
          title: "APP_VERSION",
          icon: "far fa-calendar-check",
        },
        {
          name: "Profile",
          routeName: "change_password",
          title: "ADMIN",
          icon: "fas fa-user-lock",
        },
      ],
    };
  },
  components: {},
  created() {
    this.menuActive();
  },
  computed: mapState(["accountInfo"]),
  watch: {
    "$store.state.accountInfo": function() {
      this.getPermission();
    },
  },
  methods: {
    getPermission() {
      if (this.$store.state.accountInfo) {
        let permissions;
        permissions = this.$store.state.accountInfo.role.permissionGroups;

        const menu = this.menu;

        let permission = [];

        let mainMenu = menu.filter(function(menu) {
          return permissions.some(function(el) {
            if (menu.title === el.groupName) {
              permission.push(el);
            }
            return menu.title === el.groupName;
          });
        });

        let children = [];
        let menuReport = [];
        mainMenu.find((item, index) => {
          if (
            item.title == "ADMIN" ||
            item.title == "ROLE" ||
            item.title == "APP_VERSION"
          ) {
            children.push(item);
            mainMenu[index].hidden = true;
          }

          if(item.title == "REPORT" && item.name != "Dashboard"){
            menuReport.push(item);
            mainMenu[index].hidden = true;
          }
        });

        if (menuReport.length > 0) {
          let obj = {
            name: "Report",
            routeName: "",
            title: "Report",
            icon: "fas fa-file",
            dropdown: false,
            children: menuReport,
          };
          mainMenu.push(obj);
        }

        if (children.length > 0) {
          let obj = {
            name: "Setting",
            routeName: "",
            title: "Setting",
            icon: "fas fa-key",
            dropdown: false,
            children: children,
          };
          mainMenu.push(obj);
        }
        this.menu_list = mainMenu;
        this.$store.dispatch("storePermission", permission);
      }
    },

    open(link) {
      this.$electron.shell.openExternal(link);
    },
    dorpDown2(index) {
      let item = this.menu_list[index]
      item.dropdown = !item.dropdown
    },
    menuActive() {
      let menu = this.$root.$route.name;
      if (menu) {
        this.display.menu = menu;
      } else {
        this.display.menu = "user_list";
      }
    },
  },
};
