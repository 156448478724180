import Pagination from "@/components/share/pagination"
import DetailMovie from "./../../../components/card/detail-movie"
import Service from "./../../../utils/api/service"
import Helper from "./../../../utils/global/func"
import DatePicker from "vue2-datepicker"
import TableLoading from "./../../../components/share/table-loading"
import MovieSale from "./../../../components/movie/movie-sale"
import "vue2-datepicker/index.css"
import {
  mapState
} from 'vuex'

export default {
  name: "movie_list",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      data: {
        movies: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0
        }
      },
      search: {
        key: "",
        type: "",
        status: "",
        startDate: null,
        endDate: null,
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false
          },
          imagePreview: {
            show: false,
            index: "",
          },
          report: {
            index: -1,
            show: false
          }
        }
      }
    };
  },
  components: {
    Pagination,
    DetailMovie,
    DatePicker,
    TableLoading,
    MovieSale
  },
  created() {
    this.getMovies()
  },
  watch: {
    '$route.fullPath': function () {
      this.getMovies()
    },
  },
  mounted() {

  },
  computed: {
    ...mapState([
      'movieList',
      'movieIsUpdating',
      'permissions'
    ])
  },
  methods: {
    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if (permision.name == permissionName) {
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "Customer";
        let permissions = this.permissions;

        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },
    getFullImage(path) {
      return Helper.getFullImage(path)
    },

    getMovies() {
      let queryPage = this.$root.$route.query.page
      let queryLimit = this.$root.$route.query.limit
      let querySearch = this.$root.$route.query.search
      let queryType = this.$root.$route.query.type
      let queryStatus = this.$root.$route.query.status
      
      if (queryLimit != undefined) this.data.pagination.limit = queryLimit
      if (queryPage != undefined) this.data.pagination.page = queryPage
      if (querySearch != undefined) this.search.key = querySearch
      if (queryType != undefined) this.search.type = queryType
      if (queryStatus != undefined) this.search.status = queryStatus

      let offset = (this.data.pagination.limit * this.data.pagination.page) - this.data.pagination.limit
      let param = "?limit=" + this.data.pagination.limit + "&offset=" + offset + "&search=" + this.search.key
      if (this.search.type) {
        param = param + "&type=" + this.search.type
      }
      if (this.search.status) {
        param = param + "&status=" + this.search.status
      }

      Service.getAllMovie(param)
        .then((response) => {
          if (response.statusCode) {
            if (response.statusCode == "4410") {
              Service.refreshToken().then((response) => {
                if (response == "ok") {
                  this.getMovies()
                }
              })
            } else {
              this.$toasted.show(response.message.capitalize())
            }
          } else {
            this.isFetching = false
            let movies = response.data
            this.movieIsUpdating.find((m) => {
              movies.find((item, i) => {
                if (item.id === m.movie.id) {
                  movies[i].isUploading = true
                  return true;
                }
              })
            })

            this.$store.dispatch("setMovieList", response.data);
            // this.data.movies = response.data
            this.data.pagination = Helper.calculatePagination(response.meta)
          }
        })
    },

    async searchMovie() {
      const query = Object.assign({}, this.$route.query)
      query.search = this.search.key
      query.type = this.search.type
      query.status = this.search.status
      query.page = 1
      await this.$router.push({
        query
      })
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false
          },
          imagePreview: {
            show: false,
            index: "",
          },
          report: {
            index: -1,
            show: false
          }
        }
      }
    },

    modalDetail() {
      this.display.modal.detail = true;
    },

    popupModalDelete(index) {
      this.display.modal.delete.index = index
      this.display.modal.delete.show = true
    },

    popupModalReport(index){
      this.display.modal.report.index = index
      this.display.modal.report.show = true
    },

    imagePreview(index) {
      if (this.movieList[index].thumbnailPortrait) {
        this.display.modal.imagePreview.index = index
        this.display.modal.imagePreview.show = true
      }
    },

    deleteMovie() {
      this.isDeleting = true
      let index = this.display.modal.delete.index
      let param = this.data.movies[index].id;

      Service.deleteMovie(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.deleteMovie()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.data.movies.splice(index, 1)
          this.$toasted.show("Movie was deleted.")
          this.isDeleting = false
          this.closeModal()
        }
      })
    },
  },
};