import Service from "../../../utils/api/service";

export default {
  name: "role_create",
  data() {
    return {
      isCreating: false,
      display: {
        modal: {
          formError: false,
          media: false,
        },
        message: {
          responseError: "",
        },
      },
      body: {
        name: "",
        permissionId: [],
      },
      permission: [],
    };
  },
  components: {},
  computed: {},
  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav);
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("beforeunload", this.preventNav);
  //   });
  // },

  // beforeRouteLeave(to, from, next) {
  //   if (this.body.name || this.body.permissionId.length > 0) {
  //     if (!window.confirm("Leave without saving?")) {
  //       return;
  //     }
  //   }
  //   next();
  // },

  created() {
    this.getPermission();
  },
  methods: {
    preventNav(event) {
      if (!this.body.name || !this.body.permissionId.length > 0) return;
      event.preventDefault();
      event.returnValue = "";
    },

    goBack() {
      this.$router.push({
        name: "role_list",
        query: { page: 1, limit: 10 },
      });
    },
    closeModal() {
      this.display.modal.formError = false;
      this.display.modal.media = false;
    },
    getPermission() {
      Service.getAllPermission().then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.getPermission()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.permission = response.data;
          let admin = response.data.find(function(obj){
            return obj.name === 'ADMIN'
          })

          admin.permissions.forEach(element => {
            if (element.name === 'GET_DETAIL_ADMIN_ACCOUNT') {
              this.body.permissionId.push(element.id)
            }
          });
        }
      });
    },
    checkGroupPermission(event, permission){
      let isChecked = event.target.checked
      if(isChecked){
        for(let i=0; i<permission.permissions.length; i++){
          this.body.permissionId.push(permission.permissions[i].id)
        }

        this.body.permissionId = this.body.permissionId.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        })
      }else{
        let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
        })

        for(let j=0; j<permission.permissions.length; j++){
          let id = permission.permissions[j].id
          const index = uniqueId.indexOf(id);
          if (index > -1) {
            uniqueId.splice(index, 1);
          }

          this.body.permissionId = uniqueId
        }
      }
    },
    checkPermission(event, permission, child){
      if(permission.name == "APP_VERSION"){
        if(child.name != "LIST_APP_VERSION"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_APP_VERSION" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_APP_VERSION") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "CUSTOMER"){
        if(child.name != "LIST_CUSTOMER_ACCOUNT"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_CUSTOMER_ACCOUNT" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_CUSTOMER_ACCOUNT") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "NOTIFICATION"){
        if(child.name != "LIST_SCHEDULE_NOTIFICATION"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_SCHEDULE_NOTIFICATION" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_CUSTOMER_ACCOUNT") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "PAYMENT"){
        if(child.name != "GET_PAYMENT_OPTIONS"){
          let result = permission.permissions.filter(item => { return item.name == "GET_PAYMENT_OPTIONS" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "GET_PAYMENT_OPTIONS") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "ADVERTISEMENT"){
        if(child.name != "LIST_ADVERTISEMENT"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_ADVERTISEMENT" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_ADVERTISEMENT") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "ROLE"){
        if(child.name != "LIST_ADMIN_ROLE"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_ADMIN_ROLE" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_ADMIN_ROLE") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }

        if(child.name == "UPDATE_ADMIN_ROLE"){
          let result = permission.permissions.filter(item => { return item.name == "GET_ADMIN_ROLE_DETAIL" })
          this.body.permissionId.push(result[0].id)
        }
      }else if(permission.name == "ADMIN"){
        if(child.name != "LIST_ADMIN_ACCOUNT"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_ADMIN_ACCOUNT" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_ADMIN_ACCOUNT") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "ORDER"){
        if(child.name != "LIST_ORDER"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_ORDER" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_ORDER") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }
      }else if(permission.name == "MOVIE"){
        if(child.name != "LIST_MOVIE"){
          let result = permission.permissions.filter(item => { return item.name == "LIST_MOVIE" })
          this.body.permissionId.push(result[0].id)
        }else{
          let isExisted = false
          let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
          })
          for(let k=0; k<permission.permissions.length; k++){
            if(permission.permissions[k].name != "LIST_MOVIE") {
              if(!isExisted){
                if(uniqueId.indexOf(permission.permissions[k].id) > -1){
                  isExisted = true
                }
              }
            }
          }
          event.target.checked = isExisted
        }

        if(child.name == "UPDATE_MOVIE"){
          let result = permission.permissions.filter(item => { return item.name == "GET_MOVIE_DETAIL" })
          this.body.permissionId.push(result[0].id)
        }
      }
    },
    submitCreateAdvertisement() {
      let validatedMessage = this.validateBody(this.body);
      if (validatedMessage == "ok") {
        this.isCreating = true;
        this.createRole();
      } else {
        this.$toasted.show(validatedMessage);
      }
    },
    validateBody(data) {
      if (!data.name) {
        return "Name cannot be empty!";
      } else if (!data.permissionId) {
        return "Permission cannot be empty!";
      } else {
        return "ok";
      }
    },
    createRole() {
      let uniqueId = this.body.permissionId.filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      })

      let body = {
        name: this.body.name,
        permissionId: uniqueId,
      };

      Service.createRole(body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.createRole()}
            })
          }else{
            this.isCreating = false;
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isCreating = false;
          this.resetBody();
          this.$toasted.show("Role was created.");
        }
      });
    },

    resetBody() {
      this.body = {
        name: "",
        permissionId: [],
      };
    },
  },
};
