import Service from "../../../utils/api/service";
import TableLoading from "./../../../components/share/table-loading";
import Helper from "./../../../utils/global/func"
import { mapState } from "vuex";
import Pagination from "@/components/share/pagination";

export default {
  name: "account_list",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      isUpdating: false,
      data: {
        account: [],
        roleList: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        }
      },
      search: {
        status: "",
        type: "",
      },
      field: {
        index: "",
        type: "",
      },
      display: {
        modal: {
          small: false,
          delete: {
            index: -1,
            show: false,
          },
          updateRole:{
            index: -1,
            show: false,
          }
        },
      },
      update:{
        roleId: 0
      }
    };
  },
  components: {
    TableLoading,
    Pagination
  },
  created() {
    this.getAllAccount();
    this.getAllUser(10, 0);
  },
  watch: {
    "$route.fullPath": function() {
      this.getAllAccount()
    }
  },
  mounted() {},
  computed: mapState(["accountInfo","permissions"]),
  methods: {
    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "Customer";
        let permissions = this.permissions;

        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },
    getAllUser(limit, offset) {
      let param = "?limit=" + limit + "&offset=" + offset;

      Service.getAllRole(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.getAllUser();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.isFetching = false;
          this.data.roleList = response.data;
        }
      });
    },
    getAllAccount() {
      let queryPage = this.$root.$route.query.page;
      let queryLimit = this.$root.$route.query.limit;
      let queryStatus = this.$root.$route.query.status;
      let queryType = this.$root.$route.query.type;
      if (queryPage != undefined) this.data.pagination.page = queryPage;
      if (queryLimit != undefined) this.data.pagination.limit = queryLimit;
      if (queryStatus != undefined) this.search.status = queryStatus;
      if (queryType != undefined) this.search.type = queryType;

      let offset = (this.data.pagination.limit * this.data.pagination.page) - this.data.pagination.limit
      let param = "?limit=" + this.data.pagination.limit + "&offset=" + offset

      if (this.search.status) param = param+"&status=" + this.search.status
      if (this.search.type) param = param+"&role=" + this.search.type

      Service.getAllAccount(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.getAllAccount();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.isFetching = false
          this.data.account = response.data
          this.data.pagination = Helper.calculatePagination(response.meta)
        }
      });
    },

    async searchAccount() {
      const query = Object.assign({}, this.$route.query);
      query.type = this.search.type;
      query.status = this.search.status;
      query.page = 1;
      await this.$router.push({ query });
    },

    updateRole(index){
      this.display.modal.updateRole.index = index
      this.display.modal.updateRole.show = true
    },

    confirmUpdateRole(){
      this.isUpdating = true
      if(this.update.roleId > 0){
        let roleIndex = this.data.roleList.findIndex((item) => item.id == this.update.roleId)
        console.log("role index ", roleIndex)
        let account = this.data.account[this.display.modal.updateRole.index]
        let body = {
          "accountId": account.id,
          "roleId": this.update.roleId
        }
        Service.updateRolePermission(body).then((response) => {
          if (response.statusCode) {
            if (response.statusCode == "4410") {
              Service.refreshToken().then((response) => {
                if (response == "ok") {
                  this.confirmUpdateRole();
                }
              });
            } else {
              this.$toasted.show(response.message.capitalize());
            }
          } else {
            this.isUpdating = false
            this.data.account[this.display.modal.updateRole.index].role = this.data.roleList[roleIndex].name
            this.update.roleId = 0
            this.closeModal()
          }
        })
      }else{
        this.$toasted.show('Role is required.')
      }
    },

    linkPage(type, index) {
      if (index >= 0) {
        this.$router.push({
          name: type,
          query: { id: this.data.account[index].id },
        });
      } else {
        this.$router.push({
          name: type,
        });
      }
    },

    modalPopup(type, index) {
      if (type) {
        this.field.type = type;
        this.field.index = index;
        this.display.modal.small = true;
      } else {
        this.display.modal.delete.show = true;
        this.display.modal.delete.index = index
      }
    },

    confirmDelete() {
      this.isDeleting = true;
      let accountId = this.data.account[this.field.index].id;

      Service.deleteAccount(accountId).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.confirmDelete();
              }
            });
          } else {
            this.isDeleting = false;
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.isDeleting = false;
          alert(this.display.modal.delete.index)
          this.data.account.splice(this.display.modal.delete.index, 1);
          this.$toasted.show("Account was deleted.");
          this.closeModal();
        }
      });
    },

    confirmUpdate() {
      let id = this.data.account[this.field.index].id;
      let body = {
        status: this.field.type,
      };

      Service.updateAccount(id, body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.confirmUpdate();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.display.modal.small = false;
          if (body.status == "inactive") {
            this.data.account[this.field.index].status = "inactive";
          } else {
            this.data.account[this.field.index].status = "active";
          }
          this.$toasted.show("Account was updated.");
        }
      });
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          small: false,
          updateRole:{
            index: -1,
            show: false,
          }
        },
      };
    },

    modalDetail() {
      this.display.modal.detail = true;
    },
  },
};
