import Url from "./../../utils/api/url";
import AreaChart from "./../../components/chart/area-chart.vue";
import BarChart from "./../../components/chart/bar-chart.vue";
import PieChart from "./../../components/chart/pie-chart.vue";
import { mapState } from "vuex";

export default {
  name: "report-order",
  components: {
    AreaChart,
    BarChart,
    PieChart,
  },
  data() {
    return {
      data: {
        customers: 0,
        movies: 0,
        successOrder: 0,
        watching: 0,
        paymentMethod: [],
        userAccount: [],
      },
      userAccountAreaChart: {
        labels: [],
        datasets: [
          {
            label: "Customer Registration",
            borderColor: "#1a222e",
            pointBackgroundColor: "#1a222e",
            borderWidth: 1,
            pointBorderColor: "white",
            backgroundColor: this.gradient,
            data: [],
          },
        ],
      },
      paymentMethodPieChart: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#005c76", "#c8a337", "#00851a", "#e4872e", "#a1abb0"],
            data: [],
          },
        ],
      },
    };
  },
  computed: mapState(["accountInfo", "permissions"]),
  watch: {},
  created() { },
  mounted() {
    this.getReportGraphData();
  },
  methods: {
    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });
        return result;
      }
    },

    getReportGraphData() {
      let token = this.$cookies.get("accessToken");
      let header = {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      };

      this.$axios.get(Url.getReportGraphData, header).then((response) => {
        this.data.customers = response.data.customers;
        this.data.movies = response.data.movies;
        this.data.successOrder = response.data.successOrder;
        this.data.watching = response.data.watching;
        this.data.paymentMethod = response.data;
        this.mapDataPaymentMethodToPieChart();
      });

      this.$axios.get(Url.getReportUserGraphData, header).then((response) => {
        this.data.userAccount = response.data.data;
        this.mapDataUserAccountToAreaChart();
      });
    },
    mapDataPaymentMethodToPieChart() {
      let itemLength = this.data.paymentMethod.paymentMethodsPieChart.length
      for ( let i = 0; i < itemLength; i++ ) {
        let item = this.data.paymentMethod.paymentMethodsPieChart[i];
        let chartLabel = item.name +" : "+ item.amount
        this.paymentMethodPieChart.labels.push(chartLabel);
        this.paymentMethodPieChart.datasets[0].data.push(parseInt(item.amount));
      }
    },
    mapDataUserAccountToAreaChart() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      for (let i = 0; i < this.data.userAccount.length; i++) {
        let item = this.data.userAccount[i];
        const date = new Date(item.date + "-01");
        this.userAccountAreaChart.labels.push(monthNames[date.getMonth()]);
        this.userAccountAreaChart.datasets[0].data.push(parseInt(item.count));
      }
    },
  },
};
