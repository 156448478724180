const baseUrl = process.env.VUE_APP_BASE_URL;
const admin = '/v1.1.0/admin'
const api = {
  //login
  login: baseUrl + admin + '/auth/login',
  getNewToken: baseUrl + admin + '/auth/regenerate-token',

  //admin account
  account: baseUrl + admin + '/account',
  
  //presign
  presign: baseUrl + admin + '/upload/presign',

  //Media
  getMedia: baseUrl + admin + "/media/all",
  media: baseUrl + admin + "/media",

  //User
  getAllUser: baseUrl + admin + "/customer",
  user: baseUrl + admin + "/customer",

  //Movie
  movie: baseUrl + admin + "/movie",

  //Advertisement
  advertisement: baseUrl + admin + "/advertisement",

  //Payment
  getAllPayment: baseUrl + admin + "/payment/option",
  payment: baseUrl + admin + "/payment/option",

  //Notification
  notification: baseUrl + admin + "/notification",
  notificationSchedule: baseUrl + admin + "/notification/schedule",
  pushNotification: baseUrl + admin + "/notification/push-notification",

  //Role
  role: baseUrl + admin + "/role",
  updateRole: baseUrl + admin + "/account/update-role",

  //Permission
  permission: baseUrl + admin + "/permission",

  //Report
  getTotalPurchased: baseUrl + admin + "/report/total-purchased",
  getRentTransaction: baseUrl + admin + "/report/rent-transaction",
  getPurchasedMovie: baseUrl + admin + "/report/user-register-graph-data",
  getMovieSale: baseUrl + admin + "/report/movie-sale",

  //Report Generator
  getReportOrder: baseUrl + admin + "/report/order",
  getReportGraphData: baseUrl + admin + "/report/payment-graph-data",
  getReportUserGraphData: baseUrl + admin + "/report/user-register-graph-data",

  //History
  getUserRent : baseUrl + admin + "/user-rent",

  //App version
  appVersion: baseUrl + admin + "/app-version",

  getLog: "https://api-prod.angkordc.com" + admin + "/audit/log/detail",
};
export default api;
