import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../views/login";

import ListUser from "./../views/user/list";

import ListMovie from "./../views/movie/list";
import CreateMovie from "../views/movie/create";
import EditMovie from "../views/movie/edit";

import ListAdvertisement from "./../views/advertisement/list";
import CreateAdvertisement from "../views/advertisement/create";
import EditAdvertisement from "../views/advertisement/edit";

import ListNotification from "./../views/notification/list";
import CreateNotification from "../views/notification/create";

import ListPayment from "./../views/payment/list";
import CreatePayment from "../views/payment/create";
import EditPayment from "../views/payment/edit";

import ListRole from "./../views/role/list";
import CreateRole from "../views/role/create";
import EditRole from "../views/role/edit";

import ListAccount from "./../views/account/list";
import CreateAccount from "../views/account/create";

import ChangePassword from "../views/profile";

import ListTotalPurchased from "./../views/report/list-total-purchased";
import ListRentTransaction from "./../views/report/list-rent-transaction";
import ListPurchasedMovie from "./../views/report/purchased-movie";
import ReportCustomer from "./../views/report-customer";

import AppVersion from "./../views/app-version/list";

import Dashboard from "../views/dashboard";

import ReportGenerator from "../views/report-generator";
import UserRent from "../views/user-rent";
import MovieSale from "../views/movie-sale";

import Log from "../views/log";

const isLoggedIn = (to, from, next) => {
  if (!Vue.$cookies.get("accessToken")) {
    return router.push({ name: "login" });
  }
  return next();
};

const isNotLoggedIn = (to, from, next) => {
  if (Vue.$cookies.get("accessToken")) {
    return router.push({ name: "dashboard"});
  }
  return next();
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: ListMovie,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: isNotLoggedIn,
  },
  {
    path: "/user/list",
    name: "user_list",
    component: ListUser,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/advertisement/list",
    name: "advertisement_list",
    component: ListAdvertisement,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/advertisement/create",
    name: "advertisement_create",
    component: CreateAdvertisement,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/advertisement/edit",
    name: "advertisement_edit",
    component: EditAdvertisement,
    beforeEnter: isLoggedIn,
  },

  {
    path: "/movie/list",
    name: "movie_list",
    component: ListMovie,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/movie/create",
    name: "movie_create",
    component: CreateMovie,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/movie/edit/:id",
    name: "movie_edit",
    component: EditMovie,
    beforeEnter: isLoggedIn,
  },

  {
    path: "/notification/list",
    name: "notification_list",
    component: ListNotification,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/notification/create",
    name: "notification_create",
    component: CreateNotification,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/payment/list",
    name: "payment_list",
    component: ListPayment,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/payment/create",
    name: "payment_create",
    component: CreatePayment,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/payment/edit",
    name: "payment_edit",
    component: EditPayment,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/role/list",
    name: "role_list",
    component: ListRole,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/role/create",
    name: "role_create",
    component: CreateRole,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/role/edit",
    name: "role_edit",
    component: EditRole,
    beforeEnter: isLoggedIn,
  },

  {
    path: "/account/list",
    name: "account_list",
    component: ListAccount,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/account/create",
    name: "account_create",
    component: CreateAccount,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/admin/account/profile",
    name: "change_password",
    component: ChangePassword,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/report/total-purchased",
    name: "list_total_purchased",
    component: ListTotalPurchased,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/report/rent-transaction",
    name: "list_rent_transaction",
    component: ListRentTransaction,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/report/purchased-movie",
    name: "list_purchased_movie",
    component: ListPurchasedMovie,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/report/customer",
    name: "report_customer",
    component: ReportCustomer,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/app-version/list",
    name: "app_version_list",
    component: AppVersion,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/report/generator",
    name: "report",
    component: ReportGenerator,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/user/rent",
    name: "user_rent",
    component: UserRent,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/movie/sale",
    name: "movie_sale",
    component: MovieSale,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/log",
    name: "log",
    component: Log,
    beforeEnter: isLoggedIn,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
