import Pagination from '@/components/share/pagination'
import Service from '../../../utils/api/service'
import Helper from './../../../utils/global/func'
import TableLoading from './../../../components/share/table-loading'
import UpdatePayment from './../edit'
import { mapState } from "vuex";

export default {
  name: 'payment_list',
  data() {
    return {
      isFetching: true,
      data: {
        payments: [],
      },
      search: {
        status: '',
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false,
        },
      },
      field: {
        index: '',
      },
      isDeleting: false,
      detail: {},
    }
  },
  components: {
    Pagination,
    TableLoading,
    UpdatePayment,
  },
  created() {
    this.getAllPayment(10, 0)
  },
  mounted() {},
  computed: mapState(["permissions"]),
  watch: {
    '$route.fullPath': function () {
      this.getAllPayment()
      this.getPermission()
    },
    "$store.state.permissions": function() {
      this.getPermission()
    },
  },
  methods: {
    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "Payment";
        let permissions = this.permissions;

        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },
    getFullImage(path) {
      return Helper.getFullImage(path)
    },
    getAllPayment(limit, offset) {
      let queryStatus = this.$root.$route.query.status
      if (queryStatus != undefined) this.search.status = queryStatus
      let param = ''
      if (this.search.status) {
        param =
          '?status=' +
          this.search.status +
          '&limit=' +
          limit +
          '&offset=' +
          offset
      } else {
        param = '?limit=' + limit + '&offset=' + offset
      }

      Service.getAllPayment(param).then((response) => {
        if (response.statusCode) {
          this.$toasted.show(response.message.capitalize())
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.getAllPayment(limit, offset)
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isFetching = false
          this.data.payments = response.data
        }
      })
    },

    async searchStatus() {
      const query = Object.assign({}, this.$route.query)
      query.status = this.search.status
      await this.$router.push({ query })
    },

    linkPage(type, index) {
      this.field.index = index
      if (index >= 0) {
        this.display.modal.large = true
        this.detail = this.data.payments[index]
      } else
        this.$router.push({
          name: type,
        })
    },

    popupModalDelete(index) {
      this.display.modal.delete.index = index
      this.display.modal.delete.show = true
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false,
        },
      }
    },

    modalDetail() {
      this.display.modal.detail = true
    },

    deletePaymentMethod() {
      this.isDeleting = true
      let index = this.display.modal.delete.index
      let param = this.data.payments[index].id

      Service.deletePaymentMethod(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.deletePaymentMethod()
              }
            })
          } else {
            this.isDeleting = false
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.data.payments.splice(index, 1)
          this.$toasted.show('Payment method was deleted.')
          this.isDeleting = false
          this.closeModal()
        }
      })
    },

    updated(value) {
      this.data.payments[this.field.index] = value
    },
  },
}
