import Moment from "moment";
import Service from "./../../../utils/api/service";
import Helper from "./../../../utils/global/func";

export default {
  name: "component_movie_create",
  props:{
    componentIndex: Number
  },
  data() {
    return {
      uploadPercentage: 0,
      isCreating: false,
      isUploadingThumbnailExtra: false,
      isUploadedThumbnailLandscape: false,
      body: {
        thumbnail: {
          file: "",
          presign: ""
        },
        movie: {
          file: "",
          presign: ""
        },
        trailer: {
          file: "",
          presign: ""
        },
        thumbnailPortrait: {
          file: "",
          presign: ""
        },
        thumbnailLandscape: {
          file: "",
          presign: ""
        },
        movieUrl: "",
        title: "",
        youtubeLink: "",
        type: "coming_soon",
        status: "inactive",
        duration: "00:00:00"
      },
      isUploadedFile:{
        thumbnail : false,
        movie : false,
        trailer : false
      },
      display:{
        form : "general", // enum: general and media
        imagePreview: {
          show: false,
          type: ""
        }
      }
    };
  },
  components: {
  },
  computed: {
    date: {
      get() {
        return Moment(this.body.date).format("YYYY-MM-DD");
      },

      set(date) {
        this.body.date = Date.parse(date);
      },
    },
  },
  created() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    chooseImage(e){
      var file = e.target.files[0]
      var newFile;
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        let fileName = new Date().getTime()+".jpg"
        newFile = Helper.base64toFile(reader.result, fileName)
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }

      setTimeout(() => { this.body.thumbnail.file = newFile }, 1000);
    },

    changeMovieType(){
      if(this.body.type == "coming_soon"){
        this.body.movie = {
          file: "",
          presign: ""
        }
        this.body.youtubeLink = ""
      }else if(this.body.type == "free"){
        this.body.movie = {
          file: "",
          presign: ""
        }
        this.body.trailer = {
          file: "",
          presign: ""
        }
      }else{
        this.body.trailer = {
          file: "",
          presign: ""
        }
        this.body.youtubeLink = ""
      }
    },

    chooseThumbnailPortrait(e){
      let thumbnailPortraitFile = e.target.files[0];
      if(thumbnailPortraitFile){
        var file = thumbnailPortraitFile
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.body.thumbnailPortrait.file = newFile }, 1000);
      }
    },

    chooseThumbnailLandscape(e){
      let thumbnailLandscapeFile = e.target.files[0];
      if(thumbnailLandscapeFile){
        var file = thumbnailLandscapeFile
        var newFile;
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let fileName = new Date().getTime()+".jpg"
          newFile = Helper.base64toFile(reader.result, fileName)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }

        setTimeout(() => { this.body.thumbnailLandscape.file = newFile }, 1000);
      }
    },

    chooseMovie(e){
      let videoFile = e.target.files[0];
      if(videoFile){
        this.body.movie.file = videoFile
        this.getDuration(videoFile)
      }
    },

    getDuration(file) {
      var duration = 0;
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        duration = parseInt(video.duration) * 1000
      } 
      video.src = URL.createObjectURL(file);
      setTimeout(() => { this.body.duration = this.msToTime(duration) }, 1000);
    },

    msToTime(duration) {
      // var milliseconds = parseInt((duration % 1000) / 100),
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
    
      return hours + ":" + minutes + ":" + seconds;
    },

    chooseTrailer(e){
      let trailerFile = e.target.files[0];
      if(trailerFile){
        this.body.trailer.file = trailerFile 
      }
    },

    fileToPath(file){
      return window.URL.createObjectURL(file) 
    },

    submitCreateMovie(){
      let validatedMessage = this.validateBody(this.body)
      if(validatedMessage == "ok"){
        this.$emit('submitMovie', {
          componentIndex : this.componentIndex,
          body : this.body
        })
        this.isCreating = true
        if(this.body.thumbnail.file || this.body.movie.file || this.body.trailer.file ){
          this.uploadPresign()
        }else{
          this.createMovie()
        }
      }else{
        this.$toasted.show(validatedMessage)
      }
    },

    validateBody(data){
      if(!data.thumbnail.file){
        return "Thumbnail cannot be empty!"
      }else if(!data.movie.file && data.type == 'charge'){
        return "Movie cannot be empty!"
      }else if(!data.youtubeLink && data.type == 'coming_soon'){
        return "Youtube link cannot be empty!"
      }else if(!data.youtubeLink && data.type == 'free'){
        return "Youtube link cannot be empty!"
      }else if(!data.title){
        return "Title cannot be empty!"
      }else if(!data.type){
        return "Type cannot be empty!"
      }else{
        return "ok"
      }
    },

    uploadPresign(){
      let body = {
        media:[
          {
            ext: this.body.thumbnail.file.type.split('/').pop(),
            type: "thumbnail",
            filename: new Date().getTime()+"."+this.body.thumbnail.file.type.split('/').pop()
          }
        ]
      }
      if(this.body.type == 'charge'){
        let obj = {
          ext: this.body.movie.file.type.split('/').pop(),
          type: "movie",
          filename: this.body.movie.file.name
        }
        body.media.push(obj)
      }
      // if(this.body.type == 'coming_soon'){
      //   let obj = {
      //     ext: this.body.trailer.file.type.split('/').pop(),
      //     type: "trailer",
      //     filename: this.body.trailer.file.name
      //   }
      //   body.media.push(obj)
      // }
      Service.uploadPresign(body).then((response) => {
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.uploadPresign()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          this.body.thumbnail.presign = response.data[0]
          this.body.movie.presign = response.data[1]
          this.checkUploadedFile(this.body.thumbnail)
        }
      });
    },

    uploadThumbnailPresign(){
      let body = {
        media:[]
      }

      if(this.body.thumbnailLandscape.file){
        let obj = {
          ext: this.body.thumbnailLandscape.file.type.split('/').pop(),
          type: "thumbnail",
          filename: this.generalImageLandscapeUrl(this.body.thumbnailLandscape.file.type.split('/').pop())
        }
        body.media.push(obj)
      }

      if(body.media.length > 0){
        Service.uploadPresign(body).then((response) => {
          if(response.statusCode){
            if(response.statusCode == "4410"){
              Service.refreshToken().then((response) => {
                if(response == "ok"){this.uploadThumbnailPresign()}
              })
            }else{
              this.$toasted.show(response.message.capitalize())
            }
          }else{
            this.body.thumbnailLandscape.presign = response.data[0]
            this.checkThumbnailExtra()
          }
        })
      }else{
        this.createMovie()
      }
    },

    generalImageLandscapeUrl(fileExt){
      let name = this.body.thumbnail.presign.key.split('/').pop()
      let ext = name.split('.').pop()
      let newName = name.split('.'+ext)[0]
      return newName+"-landscape."+fileExt
    },

    async checkThumbnailExtra(){
      if(this.body.thumbnailLandscape.file && this.body.thumbnailLandscape.presign && this.isUploadedThumbnailLandscape){
        this.createMovie()
      }else{
        this.isUploadingThumbnailExtra = true
        this.uploadFile(this.body.thumbnailLandscape)
      }
    },

    async checkUploadedFile(){
      if(this.body.type == "charge"){
        this.isUploadedFile.trailer = true
      }

      if(!this.isUploadedFile.thumbnail){
        await this.uploadFile(this.body.thumbnail)
      }else if(!this.isUploadedFile.movie && this.body.type == "charge"){
        await this.uploadFile(this.body.movie)
      }else{
        this.uploadThumbnailPresign()
      }
    },

    async uploadFile(item){
      let uploadUrl = item.presign.uploadUrl
      let file = item.file
      if(item){
        let headers = {
          headers: {
            "Content-Type": file.type
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            this.$emit('uploadedPercentage', {
              componentIndex : this.componentIndex,
              percentage : this.uploadPercentage,
              type: 'create'
            })
          }.bind(this)
        };

        await this.$axios
        .put(uploadUrl, file, headers)
        .then(() => {
          if(this.isUploadingThumbnailExtra) {
            this.isUploadedThumbnailLandscape = true
            this.checkThumbnailExtra()
          }else{
            if(!this.isUploadedFile.thumbnail){
              this.isUploadedFile.thumbnail = true
              this.checkUploadedFile()
            }else if(!this.isUploadedFile.movie){
              this.isUploadedFile.movie = true
              this.checkUploadedFile()
            }else{
              this.isUploadedFile.trailer = true
              this.checkUploadedFile()
            }
          }
        })
      }else{
        this.$toasted.show("File cannot be empty!")
      }
    },

    createMovie() {
      let body = {
        thumbnailPortrait: this.body.thumbnail.presign.key,
        thumbnailLandscape: this.body.thumbnailLandscape.presign?this.body.thumbnailLandscape.presign.key:"",
        trailerUrl: this.body.youtubeLink,
        movieUrl: this.body.type == 'free' 
          ? this.body.youtubeLink 
          : this.body.type == 'charge' 
            ? this.body.movie.presign.key
            : "",
        title: this.body.title,
        type: this.body.type,
        uuid: this.body.type == 'charge' 
          ? this.body.movie.presign.uuid
          : this.body.thumbnail.presign.uuid,
        status: this.body.status,
        duration: this.body.duration && this.body.duration != 0 ? Helper.timeToMillisecond(this.body.duration): 0
      }

      Service.createMovie(body).then((response) => {
        this.isCreating = false
        if(response.statusCode){
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.createMovie()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        }else{
          this.$emit('submitMovie', {
            componentIndex : this.componentIndex,
            body : ""
          })
          this.resetBody()
          this.$toasted.show("Movie was created.")
        }
      });
    },

    resetBody(){
      this.$refs['chooseThumbnail'+this.componentIndex].value=null
      this.$refs['chooseThumbnailLandscape'+this.componentIndex].value=null

      if(this.body.type == 'charge'){
        this.$refs['chooseMovie'+this.componentIndex].value=null
      }
      
      this.uploadPercentage = 0
      this.isCreating = false
      this.isUploadingThumbnailExtra = false
      this.isUploadedThumbnailLandscape = false
      this.body = {
        thumbnail: {
          file: "",
          presign: ""
        },
        movie: {
          file: "",
          presign: ""
        },
        trailer: {
          file: "",
          presign: ""
        },
        thumbnailPortrait: {
          file: "",
          presign: ""
        },
        thumbnailLandscape: {
          file: "",
          presign: ""
        },
        movieUrl: "",
        title: "",
        youtubeLink: "",
        type: "coming_soon",
        status: "inactive",
        duration: 0
      }
      this.isUploadedFile = {
        thumbnail : false,
        movie : false,
        trailer : false
      }
      this.display = {
        form : "general", // enum: general and media
        imagePreview: {
          show: false,
          type: ""
        }
      }
    },

    imagePreview(type){
      if (this.body.thumbnail.file || this.body.thumbnailLandscape.file) {
        this.display.imagePreview.show = true
        if (type == 'portrait') {
          this.display.imagePreview.type = 'portrait'
        } else{
          this.display.imagePreview.type = 'landscape'
        }
      }
    },

    closeModal(){
      this.display.imagePreview.show = false
    }
  },
};