import Service from './../../../utils/api/service'
import Helper from './../../../utils/global/func'

export default {
  name: 'payment_edit',
  props: {
    detail: Object,
  },
  data() {
    return {
      isCreating: false,
      body: {
        logo: {
          file: '',
          presign: '',
        },
      },
      paymentDetail: { ...this.detail },
    }
  },
  components: {},
  created() {},
  methods: {
    goBack() {
      this.$router.go(-1)
    },

    getFullImage(path) {
      return Helper.getFullImage(path)
    },

    closeModal() {
      this.$parent.display.modal.large = false
    },

    chooseLogo(e) {
      this.body.logo.file = e.target.files[0]
    },

    fileToPath(file) {
      return window.URL.createObjectURL(file)
    },

    getNameFromUrl(url) {
      return url.split('/').pop()
    },

    submitUpdatePayment() {
      let validatedMessage = this.validateBody(this.paymentDetail)
      if (validatedMessage == 'ok') {
        this.isCreating = true

        if (this.body.logo.file) {
          this.uploadPresign()
        } else {
          this.updatePayment()
        }
      } else {
        this.$toasted.show(validatedMessage)
      }
    },

    uploadPresign() {
      let body = {
        media: [
          {
            ext: this.body.logo.file.type.split('/').pop(),
            type: 'paymentMethod',
            filename: this.body.logo.file.name,
          },
        ],
      }
      Service.uploadPresign(body).then((response) => {
        this.body.logo.presign = response.data
        this.uploadFile(this.body.logo.file)
      })
    },

    async uploadFile(file) {
      if (file) {
        let uploadUrl = this.body.logo.presign[0].uploadUrl
        await Service.uploadMedia(uploadUrl, file, file.type).then(
          (response) => {
            if (response.statusCode) {
              if (response.statusCode == '4410') {
                Service.refreshToken().then((response) => {
                  if (response == 'ok') {
                    this.uploadFile(file)
                  }
                })
              } else {
                this.$toasted.show(response.message.capitalize())
              }
            } else {
              this.updatePayment()
            }
          },
        )
      } else {
        this.$toasted.show('File cannot be empty!')
      }
    },

    updatePayment() {
      let id = this.paymentDetail.id
      if (this.body.logo.file) {
        this.paymentDetail.logoUrl = this.body.logo.presign[0].key
      }

      let body = {
        logoUrl: this.paymentDetail.logoUrl,
        name: this.paymentDetail.name,
        price: parseFloat(this.paymentDetail.price),
        sortOrder: parseInt(this.paymentDetail.sortOrder),
        status: this.paymentDetail.status,
      }

      Service.updatePayment(id, body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.updatePayment()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          const updatedBody = Object.assign({}, body)
          updatedBody.id = this.paymentDetail.id
          updatedBody.createdAt = this.paymentDetail.createdAt
          updatedBody.updatedAt = this.paymentDetail.updatedAt
          this.$emit('clicked', updatedBody)

          this.isCreating = false
          this.$parent.display.modal.large = false
          this.$toasted.show('Payment method was updated.')
        }
      })
    },

    validateBody(data) {
      if (!data.logoUrl) {
        return 'Logo file cannot be empty!'
      } else if (!data.name) {
        return 'Name cannot be empty!'
      } else if (!data.price) {
        return 'Price cannot be empty!'
      } else if (!data.sortOrder) {
        return 'Sort order cannot be empty!'
      } else if (!data.status) {
        return 'Status order cannot be empty!'
      } else {
        return 'ok'
      }
    },

    resetBody() {
      this.isCreating = false
      this.body = {
        logo: {
          file: '',
          presign: '',
        },
        name: '',
        price: '',
        sortOrder: '',
        status: 'active',
      }
    },
  },
}
