import CreateMovie from './../../../components/movie/create'
export default {
  name: "movie_create",
  data() {
    return {
    };
  },
  components: {
    CreateMovie
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
};