import Service from "./../../../utils/api/service";
import TableLoading from "./../../../components/share/table-loading";
import "vue2-datepicker/index.css";
import { mapState } from 'vuex'

export default {
  name: "app-version",
  data() {
    return {
      isFetching: true,
      isUpdating: false,
      data: {
        versions: [],
      },
      update: {
        data: "",
        show: false,
        index: -1
      }
    };
  },
  components: {
    TableLoading,
  },
  created() {
    this.getAppVersion();
  },
  watch: {
    "$route.fullPath": function() {
      this.getAppVersion()
      this.getPermission()
    },
    "$store.state.permissions": function() {
      this.getPermission()
    },
  },
  mounted() {},
  computed: mapState(["permissions"]),
  methods: {
    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "App Version";
        let permissions = this.permissions;

        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },
    getAppVersion() {
      Service.getAppVersion().then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.getAllUser()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isFetching = false;
          this.data.versions = response.data;
        }
      });
    },

    displayFormUpdate(appVersionIndex){
      var version = this.data.versions[appVersionIndex]
      this.update.data = {
        id: version.id,
        ios: version.ios,
        android: version.android,
        huawei: version.huawei,
        forceUpdate: version.forceUpdate.toString() == "true" ? true : false,
        maintenanceStatus: version.maintenanceStatus.toString() == "true" ? true : false
      }
      this.update.show = true
      this.update.index = appVersionIndex
    },

    confirmUpdate(){
      this.isUpdating = true
      let body = {
        ios: this.update.data.ios,
        android: this.update.data.android,
        huawei: this.update.data.huawei,
        forceUpdate: this.update.data.forceUpdate.toString() == "true" ? true : false,
        maintenanceStatus: this.update.data.maintenanceStatus.toString() == "true" ? true : false,
      }

      Service.updateAppVersion(this.update.data.id, body)
      .then((response) => {
        this.isUpdating = false
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.confirmUpdate()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.data.versions[this.update.index] = this.update.data
          this.$toasted.show("App version was updated.")
          this.closeModal()
        }
      })
    },

    closeModal(){
      this.update = {
        data: "",
        show: false,
        index: -1
      }
    }
  }
};
