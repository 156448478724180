import Service from "./../../../utils/api/service";

export default {
  name: "payment_create",
  data() {
    return {
      isCreating: false,
      body: {
        logo: {
          file: "",
          presign: "",
        },
        name: "",
        price: "",
        sortOrder: 1,
        status: "active",
      },
    };
  },
  components: {},
  created() {},

  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav);
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("beforeunload", this.preventNav);
  //   });
  // },

  // beforeRouteLeave(to, from, next) {
  //   if (this.body.logo.file || this.body.name || this.body.price) {
  //     if (!window.confirm("Leave without saving?")) {
  //       return;
  //     }
  //   }
  //   next();
  // },

  methods: {
    preventNav(event) {
      if (!this.body.logo.file || !this.body.name || !this.body.price) return;
      event.preventDefault();
      event.returnValue = "";
    },

    goBack() {
      this.$router.go(-1);
    },

    closeModal() {
      this.display.modal.formError = false;
      this.display.modal.media = false;
    },

    submitCreatePayment() {
      let validatedMessage = this.validateBody(this.body);
      if (validatedMessage == "ok") {
        this.isCreating = true;
        this.uploadPresign();
      } else {
        this.$toasted.show(validatedMessage);
      }
    },

    createPayment() {
      let body = {
        logoUrl: this.body.logo.presign[0].key,
        name: this.body.name,
        price: parseFloat(this.body.price),
        sortOrder: parseInt(this.body.sortOrder),
        status: this.body.status,
      };

      Service.createPayment(body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.createPayment()}
            })
          }else{
            this.isCreating = false
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isCreating = false
          this.resetBody();
          this.$toasted.show("Payment method was created.");
        }
      });
    },

    chooseLogo(e) {
      this.body.logo.file = e.target.files[0];
    },

    fileToPath(file) {
      return window.URL.createObjectURL(file);
    },

    uploadPresign() {
      let body = {
        media: [
          {
            ext: this.body.logo.file.type.split("/").pop(),
            type: "paymentMethod",
            filename: this.body.logo.file.name,
          }
        ],
      };
      Service.uploadPresign(body).then((response) => {
        if (response.statusCode) {
          if(response.statusCode == "4410"){
            Service.refreshToken().then((response) => {
              if(response == "ok"){this.uploadPresign()}
            })
          }else{
            this.$toasted.show(response.message.capitalize())
            this.isCreating = false
          }
        } else {
          this.body.logo.presign = response.data;
          this.uploadFile(this.body.logo.file);
        }
      });
    },

    async uploadFile(file) {
      if (file) {
        let uploadUrl = this.body.logo.presign[0].uploadUrl;
        await Service
          .uploadMedia(uploadUrl, file, file.type)
          .then((response) => {
            if (response == "ok") {
              this.createPayment();
            }else{
              this.$toasted.show("File upload fail!");
              this.isCreating = false
            }
          });
      } else {
        this.$toasted.show("File cannot be empty!");
      }
    },

    validateBody(data) {
      if (!data.logo.file) {
        return "Logo file cannot be empty!";
      } else if (!data.name) {
        return "Name cannot be empty!";
      } else if (!data.price) {
        return "Price cannot be empty!";
      } else if (!data.sortOrder) {
        return "Sort order cannot be empty!";
      } else if (!data.status) {
        return "Status order cannot be empty!";
      } else {
        return "ok";
      }
    },

    resetBody() {
      this.isCreating = false;
      this.body = {
        logo: {
          file: "",
          presign: "",
        },
        name: "",
        price: "",
        sortOrder: "",
        status: "active",
      };
    },
  },
};
