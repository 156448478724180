import axios from "axios";
import VueCookies from "vue-cookies";
import api from "./url";

const service = {};

service.headers = function() {
  let token = VueCookies.get("accessToken");
  if (token) {
    let header = {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    };
    return header;
  } else {
    service.logout();
  }
};

service.headerWithoutToken = function() {
  let header = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return header;
};

service.validateError = function(error) {
  let httpCode = error.response.status;
  console.log(httpCode);
  switch (httpCode) {
    case 401:
      if (error.response.data.statusCode == "4410") {
        return error.response.data;
      } else {
        service.logout();
      }
      break;
    default:
      return error.response.data;
  }
};

service.logout = function() {
  VueCookies.remove("accountId");
  VueCookies.remove("accessToken");
  VueCookies.remove("refreshToken");
  location.reload();
};

//login
service.login = async function(body) {
  return await axios
    .post(api.login, body, service.headerWithoutToken())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.refreshToken = async function() {
  var body = {
    refreshToken: VueCookies.get("refreshToken"),
  };
  return await axios
    .post(api.getNewToken, body, service.headerWithoutToken())
    .then((response) => {
      VueCookies.set("accessToken", response.data.data.access_token);
      VueCookies.set("refreshToken", response.data.data.refresh_token);
      return "ok";
    })
    .catch(function() {
      service.logout();
    });
};

//presign
service.uploadPresign = async function(body) {
  return await axios
    .post(api.presign, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.uploadMedia = async function(uploadUrl, formData, fileType) {
  return await axios
    .put(uploadUrl, formData, {
      headers: {
        "Content-Type": fileType,
      },
    })
    .then(() => {
      return "ok";
    })
    .catch(function(error) {
      return console.log(error);
    });
};
//end presign

//==== Media====
service.getMedia = async function(limit, page) {
  return await axios
    .get(api.getMedia + "?page=" + page + "&limit=" + limit, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.getMediaDetail = async function(id) {
  return await axios
    .get(api.media + "/" + id, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.createdMedia = async function(body) {
  return await axios
    .post(api.media, body, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.deleteMedia = async function(body) {
  return await axios
    .delete(
      api.media,
      {
        data: body,
      },
      service.headers()
    )
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};

//==== User ====
service.getAllUser = async function(param) {
  return await axios
    .get(api.getAllUser + param, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.getOneUser = async function(id) {
  return await axios
    .get(api.user + "?_id=" + id, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.createUser = async function(body) {
  return await axios
    .post(api.user, body, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.deleteUser = async function(body) {
  return await axios
    .delete(
      api.user,
      {
        data: body,
      },
      service.headers()
    )
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.updateUser = async function(id, body) {
  return await axios
    .put(api.user + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Movie ====
service.getAllMovie = async function(param) {
  return await axios
    .get(api.movie + param, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.getMovieById = async function(id) {
  return await axios
    .get(api.movie + "/" + id, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.createMovie = async function(body) {
  return await axios
    .post(api.movie, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.deleteMovie = async function(id) {
  return await axios
    .delete(api.movie + "/" + id, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateMovie = async function(id, body) {
  return await axios
    .put(api.movie + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Advertisement ====
service.getAllAdvertise = async function(param) {
  return await axios
    .get(api.advertisement + param, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.getOneAdvertise = async function(id) {
  return await axios
    .get(api.advertisement + "/" + id, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.createAdvertise = async function(body) {
  return await axios
    .post(api.advertisement, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.deleteAdvertise = async function(advertisementId) {
  return await axios
    .delete(api.advertisement + "/" + advertisementId, {
      headers: service.headers().headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateAdvertise = async function(id, body) {
  return await axios
    .put(api.advertisement + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      console.log(error);
      return service.validateError(error);
    });
};

// ==== Payment Method ====
service.getAllPayment = async function(param) {
  return await axios
    .get(api.getAllPayment + param + "&sort=ASC", service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.getOnePayment = async function(id) {
  return await axios
    .get(api.payment + "/" + id, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.createPayment = async function(body) {
  return await axios
    .post(api.payment, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.deletePaymentMethod = async function(id) {
  return await axios
    .delete(api.payment + "/" + id, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updatePayment = async function(id, body) {
  return await axios
    .put(api.payment + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Notification ====
service.getAllNotification = async function(param) {
  return await axios
    .get(api.notificationSchedule + param, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

service.getFilterNotification = async function(limit, minDate, maxDate) {
  return await axios
    .get(
      api.notificationSchedule +
        "?limit=" +
        limit +
        "&minDate=" +
        minDate +
        "&maxDate=" +
        maxDate,
      service.headers()
    )
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.createNotification = async function(body) {
  return await axios
    .post(api.notificationSchedule, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.pushNotification = async function(body) {
  return await axios
    .post(api.pushNotification, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.deleteNotification = async function(notificationId) {
  return await axios
    .delete(api.notificationSchedule + "/" + notificationId, {
      headers: service.headers().headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateNotification = async function(id, body) {
  return await axios
    .put(api.notification + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Role ====
service.getAllRole = async function(param) {
  return await axios
    .get(api.role + param, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.getAdminRole = async function(id) {
  return await axios
    .get(api.role + "/" + id, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.createRole = async function(body) {
  return await axios
    .post(api.role, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.deleteRole = async function(roleId) {
  return await axios
    .delete(api.role + "/" + roleId, {
      headers: service.headers().headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateRole = async function(id, body) {
  return await axios
    .put(api.role + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

service.updateRolePermission = async function(body) {
  return await axios
    .put(api.updateRole, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Permission =====
service.getAllPermission = async function() {
  return await axios
    .get(api.permission, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Admin Account ====
service.getAllAccount = async function(param) {
  return await axios
    .get(api.account + param, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.getAccountDetail = async function() {
  return await axios
    .get(api.account + "/profile", service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.createAccount = async function(body) {
  return await axios
    .post(api.account, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateAccount = async function(id, body) {
  return await axios
    .put(api.account + "/status/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateProfile = async function(body) {
  return await axios
    .put(api.account + "/profile/", body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.updateEmail = async function(id, body) {
  return await axios
    .put(api.account + "/email/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.deleteAccount = async function(id) {
  return await axios
    .delete(api.account + "/" + id, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
service.changePassword = async function(body) {
  return await axios
    .put(api.account + "/change-password/", body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

// ==== Report ====
service.getAllReport = async function(page, limit) {
  return await axios
    .get(
      api.getAllReport + "?page=" + page + "&limit=" + limit,
      service.headers()
    )
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.getOneReport = async function(id) {
  return await axios
    .get(api.report + "?_id=" + id, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.getReportOrder = async function(params) {
  return await axios
    .get(api.getReportOrder + params, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.getReportGraphData = async function() {
  return await axios
    .get(api.getReportGraphData, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.getReportUserGraphData = async function() {
  return await axios
    .get(api.getReportUserGraphData, service.headers())
    .then((response) => {
      return service.validateError(response);
    })
    .catch(function(error) {
      return error.response.data;
    });
};
service.getMovieSale = async function(params) {
  return await axios
    .get(api.getMovieSale + params, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
// End Report

// Watch History
service.getUserRent = async function(params) {
  return await axios
    .get(api.getUserRent + params, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
// End History Watch

//app-version
service.getAppVersion = async function() {
  return await axios
    .get(api.appVersion, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};

service.updateAppVersion = async function(id, body) {
  return await axios
    .put(api.appVersion + "/" + id, body, service.headers())
    .then((response) => {
      return response.data;
    })
    .catch(function(error) {
      return service.validateError(error);
    });
};
export default service;
