import Service from "../../utils/api/service";
import Helper from "./../../utils/global/func";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "report_customer",
  data() {
    return {
      columns : [
        {
            label: "Customer ID",
            field: "id",
        },
        {
            label: "Phone Number",
            field: "phone",
        },
        {
            label: "Status",
            field: "status",
        },
        {
          label: "DateTime",
          field: "createdAt",
        },
      ],
      isGenerating: false,
      isExporting: false,
      isGenerated: false,
      data: {
        histories: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      body: {
        movieId: "",
        phone: "",
        countryCode: "855",
        status: "", // COMPLETED, ON_GOING
        startDate: "",
        endDate: "",
      },
      boxsearch: {
        key: "",
        results: [],
        focused: false,
        loading: false,
        loadingScroll: false,
        limit: 10,
        page: 1,
      },
    };
  },
  components: {
    DatePicker,
  },
  created() {},
  watch: {
    '$route.fullPath': function () {
      this.getCustomer()
    },
  },
  methods: {
    onSelect({name, iso2, dialCode}) {
      this.body.countryCode = dialCode
    },

    getFullImage(path) {
      return Helper.getFullImage(path);
    },

    getCustomer() {
        this.isGenerating = true
        let params = "?"
        
        if(this.body.status) params = params + "&status=" + this.body.status
        if(this.body.phone) params = params + "&phone=" + this.body.countryCode + parseInt(this.body.phone)
        if(this.body.startDate) params = params + "&fromDate=" + this.body.startDate
        if(this.body.endDate) params = params+ "&toDate=" + this.body.endDate

        Service.getAllUser(params)
        .then((response) => {
          if (response.statusCode) {
            if(response.statusCode == "4410"){
              Service.refreshToken().then((response) => {
                if(response == "ok"){this.getCustomer()}
              })
            }else{
              this.$toasted.show(response.message.capitalize())
            }
          } else {
            this.isGenerating = false
            if(response.data.length > 0){
              for(let i=0; i<response.data.length; i++){
                let item = response.data[i]
                item.createdAt = this.$moment(item.createdAt).format('DD-MM-YYYY HH:MM A')
              }
              this.data.histories = response.data
              this.data.pagination = Helper.calculatePagination(response.meta)
            }else{
              this.data.histories = ""
              this.$toasted.show("No data available.")
            }
          }
        })
    },

    msToTime(duration) {
      // var milliseconds = parseInt((duration % 1000) / 100),
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
    
      return hours + ":" + minutes + ":" + seconds;
    },

    closeModal(){
      this.data.historyDetail = ""
    },
  },

  beforeMount() {
    document.body.addEventListener("mouseup", this.handlemouseup);
  },
  beforeDestroy() {
    document.body.removeEventListener("mouseup", this.handlemouseup);
  },
};
