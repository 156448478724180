import Pagination from '@/components/share/pagination'
import Update from '../edit'
import DatePicker from 'vue2-datepicker'
import Service from './../../../utils/api/service'
import Helper from './../../../utils/global/func'
import TableLoading from './../../../components/share/table-loading'
import 'vue2-datepicker/index.css'
import { mapState } from 'vuex'

export default {
  name: 'advertisement_list',
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      search: {
        startDate: null,
        endDate: null,
      },
      fields: {
        id: '',
        index: '',
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false
        },
      },
      data: {
        defaultType: '',
        defaultStatus: '',
        advertisements: [],
        detail: {},
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
    }
  },
  components: {
    Pagination,
    Update,
    DatePicker,
    TableLoading,
  },
  created() {
    this.getAdvertisements()
  },
  watch: {
    '$route.fullPath': function () {
      this.getAdvertisements()
      this.getPermission()
    },
    "$store.state.permissions": function() {
      this.getPermission()
    },
  },
  mounted() {},
  computed: mapState(["permissions"]),
  methods: {
    checkoutPermission(permissionName){
      if(this.permissions){
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permision) => {
            if(permision.name == permissionName){
              result = true
            }
          })
        })
        return result
      }
    },
    getPermission() {
      if (this.permissions) {
        let title = "Customer";
        let permissions = this.permissions;

        let match = permissions.find((obj) => obj.groupName === title);

        if (match.permissions) {
          match.permissions.forEach((element) => {
            this.allowedPermission[element.name] = element.name;
          });
        }
      }
    },

    getFullImage(path) {
      return Helper.getFullImage(path)
    },

    getAdvertisements() {
      let queryPage = this.$root.$route.query.page
      let queryLimit = this.$root.$route.query.limit
      let queryType = this.$root.$route.query.type
      let queryStatus = this.$root.$route.query.status
      if (queryPage != undefined) this.data.pagination.page = queryPage
      if (queryLimit != undefined) this.data.pagination.limit = queryLimit
      if (queryType != undefined) this.data.defaultType = queryType
      if (queryStatus != undefined) this.data.defaultStatus = queryStatus

      let offset =
        this.data.pagination.limit * this.data.pagination.page -
        this.data.pagination.limit
      let param = ''
      if (this.data.defaultType && this.data.defaultStatus) {
        param =
          '?type=' +
          this.data.defaultType +
          '&status=' +
          this.data.defaultStatus +
          '&limit=' +
          this.data.pagination.limit +
          '&offset=' +
          offset
      } else if (this.data.defaultStatus) {
        param =
          '?status=' +
          this.data.defaultStatus +
          '&limit=' +
          this.data.pagination.limit +
          '&offset=' +
          offset
      } else if (this.data.defaultType) {
        param =
          '?type=' +
          this.data.defaultType +
          '&limit=' +
          this.data.pagination.limit +
          '&offset=' +
          offset
      } else {
        param = '?limit=' + this.data.pagination.limit + '&offset=' + offset
      }

      Service.getAllAdvertise(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.getAdvertisements()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isFetching = false
          this.data.advertisements = response.data
          this.data.pagination = Helper.calculatePagination(response.meta)
        }
      })
    },

    popupModal(type, index) {
      this.fields.index = index
      if (type == 'delete') {
        this.display.modal.delete.show = true 
      } else{
        this.display.modal.large = true
        this.data.detail = this.data.advertisements[index]
      }
    },

    deleteAdvertise() {
      this.isDeleting = true
      let advertisementId = this.data.advertisements[
        this.fields.index
      ].id
      Service.deleteAdvertise(advertisementId).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.deleteAdvertise()
              }
            })
          } else {
            this.isDeleting = false
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isDeleting = false
          this.data.advertisements.splice(this.fields.index, 1)
          this.$toasted.show('Advertisement was deleted.')
          this.closeModal()
        }
      })
    },

    async searchAdvertisement() {
      const query = Object.assign({}, this.$route.query)
      query.type = this.data.defaultType
      query.status = this.data.defaultStatus
      query.page = 1
      await this.$router.push({ query })
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false
        },
      }
    },

    updated(value) {
      this.data.advertisements[this.fields.index] = value
    },
  },
}