import Api from './../../utils/api/url'
import "vue2-datepicker/index.css";
import { mapState } from "vuex";

export default {
  name: 'log',
  data() {
    return {
      data: {
        log: []
      },
      body : {
        date : ""
      }
    };
  },
  components: {
  },
  created() {

  },
  watch: {
    "$route.fullPath": function() {

    }
  },
  mounted() {},
  computed: mapState(["permissions"]),
  methods: {
    getLog(){
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidHlwZSI6ImFkbWluIiwiaWF0IjoxNjI2OTM3NzE2LCJleHAiOjE2MjcwMjQxMTZ9.DXYtA6dFRPGPRh9zpWagDXMb2mC1g4GFGOm1GekkOGE"
      let header = {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      };

      let body = {
        "datetime": this.body.date //"2021-07-29-20"
      }

      this.$axios.post(Api.getLog,body,header)
      .then(response => {
        this.data.log = []
        if(response.data.statusCode){
          this.data.log = []
        }else{
          
          this.data.log = response.data
        }
      })
    } 
  },
}
