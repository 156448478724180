import Moment from "moment";
import Service from "./../../../utils/api/service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Helper from "./../../../utils/global/func";

export default {
  name: "component_movie_sale",
  props:{
    movie : Object
  },
  data() {
    return {
      output: null,
      isFiltering: false,
      data: {
        reportHtml : "",
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      body: {
        movieId: "",
        startDate: "",
        endDate: "",
      },
      boxsearch: {
        key: "",
        results: [],
        focused: false,
        loading: false,
        loadingScroll: false,
        limit: 10,
        page: 1,
      }
    };
  },
  components: {
    DatePicker
  },
  computed: {
    
  },
  created() {},
  methods: {
    async print() {
      const el = this.$refs.printMe;
      const options = {
        type: 'dataURL'
      }
      this.output = await this.$html2canvas(el, options);
      var a = document.createElement("a"); //Create <a>
      a.href = this.output; //Image Base64 Goes here
      a.download = "MovieSaleReport-"+this.getCurrentDate()+".png"; //File name Here
      a.click();
    },

    getCurrentDate(){
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();

      return dd+"-"+mm+"-"+yyyy
    },

    getFullImage(path) {
      return Helper.getFullImage(path);
    },

    getUserRent() {
      if(this.movie){
        this.isFiltering = true
        let queryPage = this.$root.$route.query.page
        let queryLimit = this.$root.$route.query.limit
        if(queryLimit != undefined) this.data.pagination.limit = queryLimit
        if(queryPage != undefined) this.data.pagination.page = queryPage

        let offset = this.data.pagination.limit * this.data.pagination.page - this.data.pagination.limit
        let params = "?limit="+this.data.pagination.limit+"&offset=" +offset
        
        if(this.movie) params = params + "&movieId=" + this.movie.id
        if(this.body.startDate) params = params + "&startDate=" + this.body.startDate
        if(this.body.endDate) params = params+ "&endDate=" + this.body.endDate

        Service.getMovieSale(params)
        .then((response) => {
          console.log(response)
          if (response.statusCode) {
            if(response.statusCode == "4410"){
              Service.refreshToken().then((response) => {
                if(response == "ok"){this.getUserRent()}
              })
            }else{
              this.$toasted.show(response.message.capitalize())
            }
          } else {
            this.isFiltering = false
            this.data.reportHtml = response
          }
        })
      }else{
        this.$toasted.show("Movie is required.")
      }
      
    },

    searchMovie() {
      let param = "";
      if (this.boxsearch.key) param = "?search=" + this.boxsearch.key;

      Service.getAllMovie(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.searchMovie();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.boxsearch.results = response.data;
        }
      });
    },

    selectMovie(index) {
      this.boxsearch.key = this.boxsearch.results[index].title;
      this.body.movieId = this.boxsearch.results[index].id;
      this.boxsearch.focused = false
    },

    clearMovie(){
      this.body.movieId = ""
      this.boxsearch = {
        key: "",
        results: [],
        focused: false,
        loading: false,
        loadingScroll: false,
        limit: 10,
        page: 1,
      }
    },

    closeModal(){
      this.$emit('closeModal')
    }
  },
};