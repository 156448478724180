import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
    state: {
      userDefault: "testing",
      accountInfo: {},
      permissions: "",
      movieList: [],
      movieIsCreating: [
          { 
              movie: "", 
              percentage: 0,
              show: true,
              cancelToken: ""
          }
      ],
      movieIsUpdating: [
          { 
            movie: "", 
            percentage: 0,
            show: true,
            isUploading: false
          }
      ]
    },

  getters: {},

  mutations: {
    accountInfo: (state, item) => {
      state.accountInfo = item;
    },
    permissions: (state, item) => {
      state.permissions = item;
    },
    SET_MOVIE_LIST (state, payload) {
        state.movieList = payload;
    },
    UPDATE_MOVIE_LIST (state, payload) {
        Vue.set(state.movieList, payload.index, payload.movie)
    },
    UPDATE_UPLOAD_PERCENTAGE (state, payload) {
        if(payload.type == 'create'){
            Vue.set(state.movieIsCreating, payload.componentIndex, payload.item)
        }else{
            Vue.set(state.movieIsUpdating, payload.componentIndex, payload.item)
        }
    },
    REMOVE_UPLOAD_PERCENTAGE (state, payload) {
        if(payload.type == 'create'){
            Vue.set(state.movieIsCreating, payload.componentIndex, payload.item)
        }else{
            Vue.set(state.movieIsUpdating, payload.componentIndex, payload.item)
        }
    }
  },

  actions: {
    storeAccountInfo({ commit }, item) {
      commit("accountInfo", item);
    },
    storePermission({ commit }, item) {
      commit("permissions", item);
    },
    setMovieList ({commit}, payload) {
      commit('SET_MOVIE_LIST', payload)
    },
  }
});
