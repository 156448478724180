import Service from "../../utils/api/service";
import Helper from "../../utils/global/func";
import { mapState } from "vuex";

export default {
  name: "update_profile",
  data() {
    return {
      show: {
        current: false,
        new: false,
      },
      body: {
        image: {
          file: "",
          presign: "",
        },
        account: {
          firstName: "",
          lastName: "",
          isUpdating: false,
        },
        password: {
          currentPassword: "",
          newPassword: "",
          isUpdating: false,
          isEditable: false,
        },
        email: "",
      },
    };
  },
  components: {},

  created() {},

  computed: mapState(["accountInfo"]),

  methods: {
    enableEdit(type) {
      if (type == "firstName") {
        document.getElementById("firstName").style.cursor = "text";
        document.getElementById("firstName").disabled = false;
      } else if (type == "lastName") {
        document.getElementById("lastName").style.cursor = "text";
        document.getElementById("lastName").disabled = false;
      } else if (type == "email") {
        document.getElementById("email").style.cursor = "text";
        document.getElementById("email").disabled = false;
      } else {
        this.body.password.isEditable = true;
      }
    },

    chooseImage(e) {
      this.body.image.file = e.target.files[0];
    },

    fileToPath(file) {
      return window.URL.createObjectURL(file);
    },

    getFullImage(path) {
      return Helper.getFullImage(path);
    },

    getNameFromUrl(url) {
      return url.split("/").pop();
    },

    uploadPresign() {
      let body = {
        media: [
          {
            ext: this.body.image.file.type.split("/").pop(),
            type: "profile",
            filename: this.body.image.file.name,
          },
        ],
      };
      Service.uploadPresign(body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.updateProfile();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.body.image.presign = response.data;
          this.uploadFile(this.body.image.file);
        }
      });
    },

    async uploadFile(file) {
      if (file) {
        let uploadUrl = this.body.image.presign[0].uploadUrl;
        await Service.uploadMedia(uploadUrl, file, file.type).then(
          (response) => {
            if (response == "ok") {
              this.updateProfile();
            } else {
              this.$toasted.show("File upload fail!");
            }
          }
        );
      } else {
        this.$toasted.show("File cannot be empty!");
      }
    },

    submitUpdateProfile() {
      let validatedMessage = this.validateInformation(this.accountInfo);
      let validatedPassword = this.validatePassword(this.body.password);

      if (validatedMessage == "ok") {
        this.body.account.isUpdating = true;
        if (this.body.image.file) {
          this.uploadPresign();
        } else {
          this.updateProfile();
          this.updateEmail(this.accountInfo.id);
        }

        if (this.body.password.isEditable) {
          if (validatedPassword == "ok") {
            this.body.account.isUpdating = true;
            this.changePassword();
          } else this.$toasted.show(validatedPassword);
        }
      } else {
        this.$toasted.show(validatedMessage);
      }
    },

    validateInformation(data) {
      if (!data.firstName) {
        return "First name cannot be empty!";
      } else if (!data.lastName) {
        return "Last name cannot be empty!";
      } else if (!data.email) {
        return "Email cannot be empty!";
      } else {
        return "ok";
      }
    },

    validatePassword(data) {
      console.log(data);
      if (!data.currentPassword) {
        return "Old password cannot be empty!";
      } else if (!data.newPassword) {
        return "New password cannot be empty!";
      } else {
        return "ok";
      }
    },

    updateProfile() {
      let image;
      if (this.body.image.file) {
        image = this.body.image.presign[0].key;
      } else image = this.accountInfo.imageUrl;

      let body = {
        firstName: this.accountInfo.firstName,
        lastName: this.accountInfo.lastName,
        imageUrl: image,
      };

      Service.updateProfile(body).then((response) => {
        this.body.account.isUpdating = false;
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.updateProfile();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.accountInfo.imageUrl = this.body.image.presign[0].key
          this.resetBody();
          this.$toasted.show("Your information was updated.");
        }
      });
    },

    updateEmail(id) {
      let email = { email: this.accountInfo.email };
      Service.updateEmail(id, email).then((response) => {
        this.body.account.isUpdating = false;
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.updateEmail();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.resetBody();
          this.$toasted.show("Your email was updated.");
        }
      });
    },

    changePassword() {
      let body = {
        currentPassword: this.body.password.currentPassword,
        newPassword: this.body.password.newPassword,
      };

      Service.changePassword(body).then((response) => {
        this.body.password.isUpdating = false;
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.changePassword();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.resetBody();
        }
      });
    },

    resetBody() {
      document.getElementById("firstName").style.cursor = "not-allowed";
      document.getElementById("firstName").disabled = true;
      document.getElementById("lastName").style.cursor = "not-allowed";
      document.getElementById("lastName").disabled = true;
      document.getElementById("email").style.cursor = "not-allowed";
      document.getElementById("email").disabled = true;

      this.body = {
        image: {
          file: "",
          presign: "",
        },
        account: {
          firstName: "",
          lastName: "",
          isUpdating: false,
        },
        password: {
          currentPassword: "",
          newPassword: "",
          isUpdating: false,
          isEditable: false,
        },
      };
    },
  },
};
